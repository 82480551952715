import {ShmPartyMatchActionCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShmPartyMatchActionCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShmPartyMatchActionCd ) {
      this._values.push(ShmPartyMatchActionCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShmPartyMatchActionCd {

    for ( const obj in ShmPartyMatchActionCd ) {
      if (ShmPartyMatchActionCd[obj] === value){
        return ShmPartyMatchActionCd[obj] as ShmPartyMatchActionCd;
      }
    }
  }
}

const ShmPartyMatchActionCdHelper = new ShmPartyMatchActionCdHelperEnumHelperClass();
export default ShmPartyMatchActionCdHelper;
