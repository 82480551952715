
export enum CustomerMatchRequestTypeCd {
	DETAILED = 'Detailed',
	IDENTIFIER = 'Identifier',
	MAD_CODE = 'MadCode',
	ORDER_ENTRY_DETAILED = 'OrderEntryDetailed',
	PARTIAL_DETAILED = 'PartialDetailed',
	PHONE_NUMBER = 'PhoneNumber',
	PND_DETAILED = 'PndDetailed',
	SIMPLE = 'Simple'}
