/**
 * ShipmentOds API
 * Version: 1.0
 * Build: 1.0.0.7
 */

import {
	ListInfo,
	AuditInfo,
	MonetaryAmount,
	ContactInfo,
	Comment,
	Status,
	ReferenceNumber,
	Weight,
	Volume,
	Phone,
	Dimensions,
	Charge,
	SupplementalReference,
	StatusException,
	Density,
	Address,
	Location,
	AccessorialUnitOfMeasureCd,
	ActionCd,
	AdvanceBeyondTypeCd,
	AppointmentNotificationStatCd,
	ArchiveControlCd,
	BillClassCd,
	BillStatusCd,
	BillToRelationCd,
	BolUsageTypeCd,
	ChargeToCd,
	CommodityClassCd,
	CommodityPackageCd,
	ConsigneeUnloadedTrailerCd,
	CountryCd,
	CurrencyCd,
	CustomsControlTypeCd,
	CustomsPortCd,
	DebtorCd,
	DeliveryInfoRequiredCd,
	DeliveryQualifierCd,
	EventLogSubTypeCd,
	EventLogTypeCd,
	ExemptReasonCd,
	FoodPoisonCd,
	HandlingUnitTypeCd,
	HazmatSourceCd,
	InvoiceCurrencyCd,
	LateTenderCd,
	LineItemChargeToCd,
	MatchedPartySourceCd,
	MatchedPartyStatusCd,
	MatchedPartyTypeCd,
	MiscLineItemCd,
	MiscLineItemPaymentMethodCd,
	MovementExceptionTypeCd,
	MovementStatusCd,
	NetExplosiveMassUomCd,
	NodeTypeCd,
	NotificationCd,
	ProStatusCd,
	RadioactivityUomCd,
	RatingCurrencyCd,
	RouteTypeCd,
	ServiceCalculationStatusCd,
	ServiceStatusCd,
	ShipmentAcquiredTypeCd,
	ShipmentCreditStatusCd,
	ShipmentDetailCd,
	ShipmentManagementRemarkTypeCd,
	ShipmentRemarkTypeCd,
	ShipmentServiceTypeCd,
	ShipmentSourceCd,
	ShipmentSpecialServiceCd,
	ShipmentVolumeMethodCd,
	ShipmentVolumeTypeCd,
	ShipperLoadedTrailerCd,
	TripNodeActivityCd,
	TripNodeStatusCd,
	TripNodeTypeCd,
	WarrantyStatusCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to retrieve load at sics for an ods shipment.
 */
export class ListLoadAtSicsForOdsShipmentQuery {
	/**
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListLoadAtSicsForOdsShipmentResp {
	/**
	 * Required: false
	 * Array: true
	 */
	loadAtSics:string[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve shipments based on customer mad code and pickup date range.
 */
export class ListOdsShipmentsByPickupDateAndCustomerRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	customerMadCode:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	pickupDateFromDate:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	pickupDateToDate:object = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListOdsShipmentsByPickupDateAndCustomerResp {
	/**
	 * Required: false
	 * Array: true
	 */
	odsShipments:OdsShipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve parent shipments based on multiple shipment instance ids that are provided.
 */
export class ListParentShipmentsForOdsShipmentIdsRqst {
	/**
	 * Shipment instance ids for parent shipment lookup.
	 * Required: true
	 * Array: true
	 */
	shipmentInstIds:number[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListParentShipmentsForOdsShipmentIdsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	parentOdsShipments:Shipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to restore the archived PRO from RPT1 into DB2 shm shipment
 */
export class RestoreArchivedShipmentRqst {
	/**
	 * Business identifier to the shipment
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
}
export class RestoreArchivedShipmentResp {
	/**
	 * Expanded Name : Instance identifier
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Business identifier to the shipment
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Date on which the shipment was picked up
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
}
/**
 * Request message to retrieve archived shipments based on multiple PRO numbers that are provided.
 */
export class ListArchivedShipmentsRqst {
	/**
	 * Business identifier for the shipment.
	 * Required: true
	 * Array: true
	 */
	proNbr:string[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListArchivedShipmentsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	archivedShipments:ArchivedShipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve archived shipments based on multiple PRO numbers that are provided.
 */
export class CreateRestoreArchivedShipmentRequestRecordRqst {
	/**
	 * Business identifier for the shipment.
	 * Required: true
	 * Array: true
	 */
	archivedShipments:ArchivedShipment[] = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
export class CreateRestoreArchivedShipmentRequestRecordResp {
	/**
	 * Required: false
	 * Array: true
	 */
	archivedShipments:ArchivedShipment[] = undefined;
}
export class ListShipmentsByCustomerAccountQuery {
	/**
	 * Expanded Name : CIS Customer Number
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 * Required: true
	 * Array: true
	 */
	cisCustNbr:number[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListShipmentsByCustomerAccountResp {
	/**
	 * Required: false
	 * Array: true
	 */
	custShipment:OdsShipmentPartyBasic[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * 
 */
export class Accessorial {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	quantityUom:string = undefined;
	/**
	 * Description of the Accessorial
	 * Required: false
	 * Array: false
	 */
	accessorialDesc:string = undefined;
	/**
	 * Accessorial Amount
	 * Required: false
	 * Array: false
	 */
	chargeAmt:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	accessorialCd:string = undefined;
}
/**
 * 
 */
export class Appointment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptTypeCd:string = undefined;
	/**
	 * A system generated sequence number to uniquely identify a nootification for a shipment
	 * Required: false
	 * Array: false
	 */
	seq:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	startDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * *** Needs Review
	 * Required: false
	 * Array: false
	 */
	apptContact:ContactInfo = undefined;
	/**
	 * This might be any special instructions for accessing customer dock
	 * Required: false
	 * Array: false
	 */
	apptNote:Comment = undefined;
	/**
	 * customer provided reference number for the appointment
	 * Required: false
	 * Array: false
	 */
	apptConfirmation:string = undefined;
	/**
	 * Reason for rescheduling the appt.
	 * Required: false
	 * Array: false
	 */
	rescheduleReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	apptStatus:Status = undefined;
	/**
	 * SIC that will delivery freight according to this appt.
	 * Required: false
	 * Array: false
	 */
	dlvrySIC:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * 
 */
export class Bond {
	/**
	 * Expanded Name : Bond number  : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 * Required: false
	 * Array: false
	 */
	bondNbr:string = undefined;
	/**
	 * Desc : Name of the City where the customs inspection is to be made.
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Desc : State of the city where the customs inspection is to be made.
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
}
/**
 * 
 */
export class ChemicalCharacteristics {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemProperShippingName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemTechnicalName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemTechnicalName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemTechnicalName3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classPrimary:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classSecondary:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classTertiary:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contentWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contentWeightUOM:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportableQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportQuantityWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportQuantityWeightUOM:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packagingGroupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specialProvision:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyGuideBookName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hotInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	residueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonousInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notOtherwiseSpecifiedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	toxicInhalationInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	passengerAircraftForbidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	commercialAircraftForbidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	massivePollutantInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	oilContainedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	temperatureUOM:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	flashPointTemperature:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyTemperature:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	controlTemperature:string = undefined;
}
/**
 * 
 */
export class CommodityBasicInfo {
	/**
	 * number of commodity items
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * Type of Package
	 * Required: false
	 * Array: false
	 */
	packageCode:string = undefined;
	/**
	 * total weight for package and item together
	 * Required: false
	 * Array: false
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 * Required: false
	 * Array: false
	 */
	tareWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Set to True if there is any Hazardous material
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
}
/**
 * 
 */
export class CommodityInfo {
	/**
	 * number of commodity items
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * packaging information
	 * Required: false
	 * Array: false
	 */
	packaging:Packaging = undefined;
	/**
	 * total weight for package and item together
	 * Required: false
	 * Array: false
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 * Required: false
	 * Array: false
	 */
	tareWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * Commodity description
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Indicates whether the commodity contains hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInfo:HazardousCharacteristics = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chemicalCharacteristics:ChemicalCharacteristics = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactPhone:Phone = undefined;
}
/**
 * ***Needs Review
 */
export class CommodityLine {
	/**
	 * Might be versions of a commodity line - original, corrected, as-rated
	 * Required: false
	 * Array: false
	 */
	cmdyLineTypeCd:string = undefined;
	/**
	 * sequence on shipment
	 * Required: false
	 * Array: false
	 */
	seq:number = undefined;
	/**
	 * ***Needs Review - will there be dimensions on commodities?
	 * Required: false
	 * Array: false
	 */
	dimensions:Dimensions = undefined;
	/**
	 * commodity and packing info including hazmat
	 * Required: false
	 * Array: false
	 */
	commodityInfo:CommodityInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	commoditySubLine:CommodityInfo[] = undefined;
	/**
	 * this will have the rate and charge information. if weight was changed based on rated as, then the new weight will be in quantity. The class will be reflected in asRatedNmfcClass
	 * Required: false
	 * Array: false
	 */
	charge:Charge = undefined;
	/**
	 * this is an FAK that has overridden the as entered class when rated
	 * Required: false
	 * Array: false
	 */
	asRatedNmfcClass:string = undefined;
	/**
	 * This is value declared by the shipper. This might have implications for the claims.
	 * Required: false
	 * Array: false
	 */
	declaredValue:MonetaryAmount = undefined;
	/**
	 * These are refenence numbers provided by the party on the bill (shipper) specific to this commodity line.
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	reference:ReferenceNumber[] = undefined;
}
/**
 * 
 */
export class CommodityRatingInfo {
	/**
	 * number of commodity items
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * Type of Package
	 * Required: false
	 * Array: false
	 */
	packageCode:string = undefined;
	/**
	 * total weight for package and item together
	 * Required: false
	 * Array: false
	 */
	grossWeight:Weight = undefined;
	/**
	 * weight of commodity without packaging
	 * Required: false
	 * Array: false
	 */
	tareWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	charge:Charge = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Set to True if there is any Hazardous material
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
}
/**
 * Reveals the criteria for the application of deficit rating
 */
export class DeficitRating {
	/**
	 * The freight class for this calculation. This will be eitther the actual class or if the customer has an FAK, it will be the FAK
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * difference between the actual weight and the nearest weight break
	 * Required: false
	 * Array: false
	 */
	deficitWght:Weight = undefined;
	/**
	 * The weight used to rate the shipment
	 * Required: false
	 * Array: false
	 */
	asRatedWeight:Weight = undefined;
	/**
	 * The rate used to calculate the freight charges
	 * Required: false
	 * Array: false
	 */
	deficitRate:MonetaryAmount = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deficitAmt:MonetaryAmount = undefined;
}
/**
 * 
 */
export class FreightExceptions {
	/**
	 * sequence number of the movement for which this exception applies
	 * Required: false
	 * Array: false
	 */
	movementSeq:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exception:StatusException = undefined;
	/**
	 * Over Short and Damage
	 * Required: false
	 * Array: false
	 */
	osdPieces:number = undefined;
}
/**
 * 
 */
export class HazardousCharacteristics {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * UN/NA Hazardous codes
	 * Required: false
	 * Array: false
	 */
	hazardousCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
}
/**
 * 
 */
export class Lading {
	/**
	 * number of lading units (pallets, cartons, etc.) with the same set of dimensions
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * Describes the type of packaging. Barrell, Create, Pallet etc.
	 * Required: false
	 * Array: false
	 */
	packageInfo:Packaging = undefined;
	/**
	 * additional description if required
	 * Required: false
	 * Array: false
	 */
	desc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	weight:Weight = undefined;
	/**
	 * This is the source of weight information - ltl.xpo or customer
	 * Required: false
	 * Array: false
	 */
	weightSrc:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volume:Volume = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dimensions:Dimensions = undefined;
	/**
	 * pounds per cubic foot
	 * Required: false
	 * Array: false
	 */
	density:Density = undefined;
	/**
	 * reference to commodity lines, trackable serial numbers, packing slip number, etc.
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * The details about the lading unit with dimensions for all pieces of the same dimensions. Both dimensions originally entered by the driver and the inspector may be included. 
 */
export class LadingPieceDimensions {
	/**
	 * sequence on shipment
	 * Required: false
	 * Array: false
	 */
	seqNbr:number = undefined;
	/**
	 * The package code for this group of pieces (lading units)
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * number of  lading units
	 * Required: false
	 * Array: false
	 */
	pieceCnt:number = undefined;
	/**
	 * dimensions recorded by either inspector, driver or derived by footprint. These may not be populated depending on source of dimensions
	 * Required: false
	 * Array: false
	 */
	dimensions:Dimensions = undefined;
}
/**
 * A NMFTA description and encoding for a particular commodity used by the motor freight industry
 */
export class NmfcItem {
	/**
	 * Numeric identifier for the commodity(s) description.
	 * Required: false
	 * Array: false
	 */
	itemNbr:number = undefined;
	/**
	 * Identifies a particular commodity(s) and may include allowable packaging.
	 * Required: false
	 * Array: false
	 */
	itemDesc:string = undefined;
	/**
	 * Identifies the item group that this item is subordinate to. For example Abrasives group 1020 and 1060 are subordinates and reference back to 1010
	 * Required: false
	 * Array: false
	 */
	itemReferenceGroup:number = undefined;
	/**
	 * A numeric ranking (class) assigned to the commodity(s) described based on its transportation characteristics. There are 18 classes: 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500. (Also see item/rule 110 and 420) 
	 * When 0 is shown in the Class field, it means the commodity(s) is subject to rates and regulations of the individual carrier. 
	 * In some cases, it is also 0 when the sub items have different class codes
	 * When Exempt is shown in the Class field, it means the commodity(s) is not subject to economic regulation. 
	 * When NotTaken is shown in the Class field, it means the commodity(s) will not be accepted for shipment.
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
	/**
	 * All the sub-items that exist for this item. The sub, if used indicates that there is further refinement of the description for the commodity(s) embraced by this item. For example if there are two ranges that have different classes because of different densities - less than and greater than 12.
	 * Required: false
	 * Array: true
	 */
	itemSub:NmfcSubItem[] = undefined;
}
/**
 * A NMFTA description and encoding for a particular commodity used by the motor freight industry
 */
export class NmfcSubItem {
	/**
	 * The sub, if used indicates that there is further refinement of the description for the commodity(s) embraced by this item. For example if there are two ranges that have different classes. less than density per cubic foot of 12 and greater than 12
	 * Required: false
	 * Array: false
	 */
	itemSubNbr:number = undefined;
	/**
	 * Numeric identifier for the maing commodity(s) description.  This identifier appears as the first part of the commodity id - 
	 * For example 1060.1 describes a commodity that 4. Abrasive wheels, other than pulp grinding, may be shipped in Package 2105. Belts, abrasive, in boxes, subject to Item 170 and having a density in pounds per cubic foot of: Less than 12 and 1060.2 is the same for > 12
	 * Required: false
	 * Array: false
	 */
	parentItemNbr:string = undefined;
	/**
	 * Further describes the item for this sub item.
	 * Required: false
	 * Array: false
	 */
	subItemDesc:string = undefined;
	/**
	 * A numeric ranking (class) assigned to the commodity(s) described based on its transportation characteristics. There are 18 classes: 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500. (Also see item/rule 110 and 420) 
	 * When 0 is shown in the Class field, it means the commodity(s) is subject to rates and regulations of the individual carrier. 
	 * When Exempt is shown in the Class field, it means the commodity(s) is not subject to economic regulation. 
	 * When NotTaken is shown in the Class field, it means the commodity(s) will not be accepted for shipment.
	 * Required: false
	 * Array: false
	 */
	nmfcClass:string = undefined;
}
/**
 * 
 */
export class Notification {
	/**
	 * identifies the reason the notification was necessary. valid values are                 1- Driver Collect   
	 * 2- NOA/DNC          
	 * 3- Construction Site
	 * 4- Residential      
	 * 5- Other
	 * Required: false
	 * Array: false
	 */
	notificationTypeCd:string = undefined;
	/**
	 * A system generated sequence number to uniquely identify a nootification for a shipment
	 * Required: false
	 * Array: false
	 */
	seq:number = undefined;
	/**
	 * date time when the notification was made. (typically a phone call)
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * This might be any special instructions for accessing customer dock
	 * Required: false
	 * Array: false
	 */
	comment:Comment = undefined;
	/**
	 * person who was contacted for the notification.
	 * Required: false
	 * Array: false
	 */
	contact:ContactInfo = undefined;
	/**
	 * Pending or Set
	 * Required: false
	 * Array: false
	 */
	notificationStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	references:ReferenceNumber[] = undefined;
}
/**
 * 
 */
export class Packaging {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageWeight:Weight = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageDimensions:Dimensions = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packageVolume:Volume = undefined;
}
/**
 * Information about measurements for the pieces on a shipment.
 */
export class ShipmentDimension {
	/**
	 * The length measurement for the piece(s)
	 * Required: false
	 * Array: false
	 */
	length:number = undefined;
	/**
	 * The width measurement for the piece(s)
	 * Required: false
	 * Array: false
	 */
	width:number = undefined;
	/**
	 * The height measurement for the piece(s)
	 * Required: false
	 * Array: false
	 */
	height:number = undefined;
	/**
	 * The weight of the piece(s)
	 * Required: false
	 * Array: false
	 */
	weight:number = undefined;
	/**
	 * The number of pieces for which the measurement and weight values apply.
	 * Required: false
	 * Array: false
	 */
	piecesCnt:number = undefined;
	/**
	 * Indicates whether the piece(s) can be stacked.
	 * Required: false
	 * Array: false
	 */
	stackableInd:boolean = undefined;
}
/**
 * 
 */
export class ShipmentParty {
	/**
	 * A sequential number used to identify a specifc shipment party in a database.
	 * Required: false
	 * Array: false
	 */
	shipmentPartySeq:number = undefined;
	/**
	 * An internal identifier to the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 * Required: false
	 * Array: false
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 * Required: false
	 * Array: false
	 */
	shipmentPartyBOLAddress:Address = undefined;
	/**
	 * This is the shipment party location information that comes from the customer that the shipment party was matched to.
	 * Required: false
	 * Array: false
	 */
	shipmentPartyLocation:Location = undefined;
	/**
	 * The time stamp of the last time that this shipment party was matched to a customer.
	 * Required: false
	 * Array: false
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * Indicates whether this shipment party is a debtor for this shipment.  Note that a 'bill-to' associated with a shipper/consignee is not a debtor.  They are actiing on behalf of the debtor.
	 * Required: false
	 * Array: false
	 */
	debtorInd:boolean = undefined;
	/**
	 * Indicates whether all shipments for this shipment party are to be billed a prepaid.
	 * Required: false
	 * Array: false
	 */
	allPrepaidInd:boolean = undefined;
	/**
	 * Indicates whether the address for this shipment party, to be used on an invoice, should be the one provided by the customer instead of the one on the customer that was matched.
	 * Required: false
	 * Array: false
	 */
	useAsEnteredInd:boolean = undefined;
	/**
	 * Indicates whether this shipment party requires a 'Proof of Delivery' document to be captured and provided with an invoice.
	 * Required: false
	 * Array: false
	 */
	proofOfDlvryRequiredInd:boolean = undefined;
	/**
	 * Indicates whether a 'Proof of Delivery' image has been recorded for this shipment party for this shipment.
	 * Required: false
	 * Array: false
	 */
	proofOfDlvryImagedInd:boolean = undefined;
	/**
	 * This iindicates that type of credit that this shipment party has.  Values: Credit; Non-credit; Bad Debt; A/R Control; Voluntary; Cash Only
	 * Required: false
	 * Array: false
	 */
	creditStatusCd:string = undefined;
	/**
	 * Defines the currency that this shipment party prefers using.
	 * Required: false
	 * Array: false
	 */
	preferredCurrency:string = undefined;
	/**
	 * This is an internal identifier to the customs broker that this shipment party uses for a shipment that will cross a border.
	 * Required: false
	 * Array: false
	 */
	brokerInstID:string = undefined;
	/**
	 * This is a business key that identifies the customs broker that this shipment party uses for a shipment that will cross a border.
	 * Required: false
	 * Array: false
	 */
	brokerBusinessKey:string = undefined;
	/**
	 * This is a code that defines the relationship that a bill-to shipment party has to the shipper and the consignee.  Values are: None; Shipper; Consignee; Both
	 * Required: false
	 * Array: false
	 */
	bil2RelationshipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
	/**
	 * Match status from CIS matching. Valid Values :
	 * The following values ARE ACTIVE for Forward Sync:
	 * Spaces - Initial value, matching activity has not yet occurred. 
	 * '70' - Full Match 
	 * '60' - Un-matched (valid name / address but does not exist in CIS)
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * '15' - Possible matches found
	 * '30' - Invalid address (Bad US postal address as per Code-1)
	 * '50' - Blank bill2 - no bill-to was entered
	 * Required: false
	 * Array: false
	 */
	mchStatCd:string = undefined;
	/**
	 * Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.
	 * Required: false
	 * Array: false
	 */
	mchInitTxt:string = undefined;
	/**
	 * Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 * Required: false
	 * Array: false
	 */
	directCd:string = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Shipper.
	 * Required: false
	 * Array: false
	 */
	bil2RelToShipperInd:boolean = undefined;
	/**
	 * Flag to indicate whether the Bill-To is related to the Consignee.
	 * Required: false
	 * Array: false
	 */
	bil2RelToConsigneeInd:boolean = undefined;
	/**
	 * Contains the customer key to an uncoded customer in the customer component. These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system. (Note that they are not currently stored in the P and D table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 * Required: false
	 * Array: false
	 */
	alternateCustNbr:string = undefined;
	/**
	 * Phone information for the party on the shipment.
	 * Required: false
	 * Array: false
	 */
	phone:Phone = undefined;
}
/**
 * 
 */
export class ShipmentPartyBasic {
	/**
	 * An internal identifier to the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custInstID:string = undefined;
	/**
	 * A business key that identifies the customer that this shipment party references.
	 * Required: false
	 * Array: false
	 */
	custMadCd:string = undefined;
	/**
	 * Identifies the type of shipment party that this is: (e.g. Shipper; Consignee; etc.  This will only be used in otherShipmentParties element
	 * Required: false
	 * Array: false
	 */
	shipmentPartyTypeCd:string = undefined;
	/**
	 * This is the name and address information that was provided by the customer directly.  This could be keyed from a paper BOL or provided via EDI or some other electronic means.  This is sometimes referred to as the 'as entered' address.
	 * Required: false
	 * Array: false
	 */
	shipmentPartyBOLAddress:Address = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRef:SupplementalReference[] = undefined;
}
/**
 * A generic representation of an indicator for special treatment of a delivery/pickup.
 */
export class ShipmentSpecialServiceSummary {
	/**
	 * The types of special services.
	 * Required: false
	 * Array: false
	 */
	specialService:ShipmentSpecialServiceCd = undefined;
	/**
	 * A boolean value indicating if the indicator type is applicable.
	 * Required: false
	 * Array: false
	 */
	applicableInd:boolean = undefined;
}
/**
 * 
 */
export class Tender {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderOfferDateTime:Date = undefined;
	/**
	 * Expiration of tender offer
	 * Required: false
	 * Array: false
	 */
	tenderExpDateTime:Date = undefined;
	/**
	 * Tender response deadline
	 * Required: false
	 * Array: false
	 */
	tenderRespDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earliestReqPkupDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latestReqPkupDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earliestDlvryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latestDlvryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderResponseCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderRejectionReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tenderID:string = undefined;
}
/**
 * Information about an archived shipment.
 */
export class ArchivedShipment {
	/**
	 * PRO number for the shipment.
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * The pickup date for the archived shipment.
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * A message that specifies the recall status of the shipment.
	 * Required: false
	 * Array: false
	 */
	recallStatus:string = undefined;
}
/**
 * Shipment from ODS.
 */
export class OdsShipment {
	/**
	 * Shipment details
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * Shipper Name
	 * Required: false
	 * Array: false
	 */
	shipperName:string = undefined;
	/**
	 * Consignee Name
	 * Required: false
	 * Array: false
	 */
	consigneeName:string = undefined;
	/**
	 * Final delivery date of the shipment
	 * Required: false
	 * Array: false
	 */
	finalDeliveryDate:string = undefined;
	/**
	 * Bill-To  Name
	 * Required: false
	 * Array: false
	 */
	billToName:string = undefined;
}
/**
 * Shipment Id in ODS.
 */
export class OdsShipmentId {
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
}
/**
 * Shipment event and trace history from ODS.
 */
export class ShipmentHistTraceEvent {
	/**
	 * Expanded Name : Instance identifier
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Date/Time the event occurred.
	 * Required: false
	 * Array: false
	 */
	occurredTmst:Date = undefined;
	/**
	 * Elapsed time between the previous and current events.
	 * Required: false
	 * Array: false
	 */
	elapsedTm:Date = undefined;
	/**
	 * Service Center where the event occurred.
	 * Required: false
	 * Array: false
	 */
	occurredSic:string = undefined;
	/**
	 * Event description.
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Origin SIC of the shipment.
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * Destination SIC of the shipment.
	 * Required: false
	 * Array: false
	 */
	destinationSic:string = undefined;
	/**
	 * Load release number of the trailer the shipment is on during the event.
	 * Required: false
	 * Array: false
	 */
	loadReleaseNbr:string = undefined;
	/**
	 * The Destination SIC of the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	loadDestinationSic:string = undefined;
	/**
	 * The trailer number the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	trailerNbr:string = undefined;
	/**
	 * The weight of the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	trailerWeightLbs:number = undefined;
	/**
	 * The cube of the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	trailerCubePct:number = undefined;
	/**
	 * The bill count of shipments on the trailer the shipment was loaded on during the event.
	 * Required: false
	 * Array: false
	 */
	shipmentsOnTrailerCnt:number = undefined;
	/**
	 * User that made the change associated with the event.
	 * Required: false
	 * Array: false
	 */
	updtBy:string = undefined;
	/**
	 * Service center where the update associated with the event occurred.
	 * Required: false
	 * Array: false
	 */
	updtAtSic:string = undefined;
}
export class OdsShipmentPartyBasic {
	/**
	 * Expanded Name :  Type code
	 * Desc : Specifies the nature of relationship of the customer with respect to the Service Item in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact. 
	 * Permitted Values:
	 * 										1=Shipper address
	 * 										2=Consignee address 
	 * 										3=Inbound bill-to address 
	 * 										4=Outbound bill-to address
	 * 										5=Requestor address
	 * 										6=Contact address 
	 * Source : Internal
	 * Required: false
	 * Array: false
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 * Required: false
	 * Array: false
	 */
	cisCustNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	shipment:OdsShipmentId = undefined;
}
export class ProNbrIdentifier {
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
}
export class OdsShipmentDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	asMatchedParty:AsMatchedParty[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:Commodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	accessorialService:AccessorialService[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbr:SuppRefNbr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	advanceBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsBond:CustomsBond[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	miscLineItem:MiscLineItem[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timeDateCritical:TimeDateCritical = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	parentShipmentId:OdsShipmentId = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	childShipmentIds:OdsShipmentId[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	siblingShipmentIds:OdsShipmentId[] = undefined;
	/**
	 * Status of the Pro from the Pro Frt Bill Index
	 * Required: false
	 * Array: false
	 */
	proFrtBillIndexStatCd:ProStatusCd = undefined;
	/**
	 * Indicates the pickup for this shipment was a Shipper Loadd and count.
	 * Required: false
	 * Array: false
	 */
	shipperLoadAndCountInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	xpoRevenueAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custPricingAgreementId:string = undefined;
	/**
	 * Indicates the presence of ops exceptions on the shipment. True=no exceptions; False=Exceptions present.
	 * Required: false
	 * Array: false
	 */
	noOpsExceptionInd:boolean = undefined;
	/**
	 * Indicates if a shipment has two or more handling exceptions.
	 * Required: false
	 * Array: false
	 */
	shipmentHandlingExceptionInd:boolean = undefined;
	/**
	 * Indicates if a shipment has two or more handling exceptions.
	 * Required: false
	 * Array: true
	 */
	linehaulDimensions:LnhDimension[] = undefined;
}
/**
 * 
 */
export class BolEventLog {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	subTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeToCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	occurredDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil21stCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil22ndCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	adminStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ediSenderShipmentId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	debtorCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Bil2Location {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceAcctPrepaid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceAcctCol:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	simpleInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceRcpntNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	directCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suppNbrReqCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditedStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doNotMatchInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CorpHrchy {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	natlAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	govtCustomerInd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pdLocationDefaultNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditedStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	raAnalystId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	natlSalesSuppId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PickupDeliveryLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	creditedStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctClassDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerSuplyNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzLimitAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dbCustomerNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardIndlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	simpleInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrchyCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesTerrCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	salesGroupNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suppNbrReqCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadPrefixTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadLocationTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnCustomerMadSuffixTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnNatlAcctInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	doNotMatchInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ExemptIncidnt {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exmptDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exmptUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxuser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxaction:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ExemptShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exmptSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exmptDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxuser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxaction:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ProMovementHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedAtSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	misloadAuthrzUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of ProMovementHist. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ProMovementHistSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ProMovementHist = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ProMovementHist = undefined;
}
/**
 * 
 */
export class ScheduleHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleIdNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	milg:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	displayUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	finalDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	etaDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	intmdlScac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	discrInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrId1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	drvrId2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduleRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	onDfrdLegInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TrailerLoadHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEvent:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentEventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLengthFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDestinationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedCbeProfitability:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	xclvInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closeDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	frzblInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	placardInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedServiceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityRoute:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	brkOrProprCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadDeckCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedRemarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerMadSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateByPgmId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updatedTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dfrdShipmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedDfrdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sealMatchCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	authrzUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poisonShipmentCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoorSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	closShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInspReqdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedLoadedDestination:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exctdUnloadDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routingRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideRvwRequiredInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Agreement {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	agrmntInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	renegDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activeInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scndryCustomerId1:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scndryCustomerId2:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	extensionCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastExtensionDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	acctExecId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	leadPaId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scndryPaId1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scndryPaId2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerAgreementSuffix:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	agreementVersNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rulesetTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	agreementRstrctnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	externalFormatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liabilityAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	singleInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	signatureRequiredCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorAgreementNegCustomerId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorAgreementSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorAgreementVerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rvsnNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class FreightBillIndex {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementUnitSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proPrefixOverrideInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billSicCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class StandardCode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	codeType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	codeValue:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shortDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effEndDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effStartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUpdateDeviceId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DeliveryNotification {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:AppointmentNotificationStatCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elecNotificationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callerId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeRefNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rescheduledReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	internalNote:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fbdsNote:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class NotificationHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elecNotificationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callerRacfId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	contactPhoneExtensionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeRefNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rescheduledReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdTimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fbdsNote:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	internalNote:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RouteShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeShipmentSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	positionOnTrailer:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headLoadedFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operatingRouteFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	diversionLoadDestinationSicCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * Holds a snapshot of RouteShipment. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class RouteShipmentSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	historyActionCd:ActionCd = undefined;
	/**
	 * The timestamp of when this snapshot was created.
	 * Required: false
	 * Array: false
	 */
	historyCreatedTimestamp:Date = undefined;
	/**
	 * Snapshot.
	 * Required: false
	 * Array: false
	 */
	routeShipment:RouteShipment = undefined;
	/**
	 * Previous values. Only attributes that are different from snapshot will be populated
	 * Required: false
	 * Array: false
	 */
	routeShipmentDiff:RouteShipment = undefined;
}
/**
 * 
 */
export class TripEquipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripEquipmentSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdFullName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthUOMUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthUOMFeetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	asgmntStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerEmptyFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	suggestedTrailerTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	updateById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalMoveToSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * 
 */
export class TripNode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeTypeCd:NodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeTypeCd:TripNodeTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedArriveDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDepartDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideOperationsInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideInstructionInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:TripNodeStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	planDistanceFromPrevNode:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	odometerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentRtndCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nodeSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	delayReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDelayMinimum:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	latitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	longitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastUsedActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stopBypassedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryWindowCalculatedOffsetMinutes:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryActivityCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupActivityCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentActivityCount:number = undefined;
	/**
	 * The planned distance from the previous node captured with up to 2 places decimal.  Added 10/08/2019
	 * Required: false
	 * Array: false
	 */
	plannedDistanceFromNodeMl:number = undefined;
	/**
	 * Identifies the type of trip; denormed from SCO_TRIP.  At present, it is needed solely for Linehaul.  Added 04/15/2020
	 * 
	 * VALID VALUES
	 * L=Linehaul
	 * (null)
	 * Required: false
	 * Array: false
	 */
	tripTypeCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates if a customer picks up or drops off freight at the dock.
	 * Required: false
	 * Array: false
	 */
	dockProfileInd:boolean = undefined;
	/**
	 * Indicates that a customer has a forklift.
	 * Required: false
	 * Array: false
	 */
	forkliftInd:boolean = undefined;
	/**
	 * The type of customer location; i.e. business/commercial, distribution center, construction site, etc.
	 * Required: false
	 * Array: false
	 */
	customerLocationType:string = undefined;
	/**
	 * The employee ID of the DSR.
	 * Required: false
	 * Array: false
	 */
	driverEmployeeId:string = undefined;
}
/**
 * Holds a snapshot of TripNode. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TripNodeSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	historyActionCd:ActionCd = undefined;
	/**
	 * The timestamp of when this snapshot was created.
	 * Required: false
	 * Array: false
	 */
	historyCreatedTimestamp:Date = undefined;
	/**
	 * Snapshot.
	 * Required: false
	 * Array: false
	 */
	tripNode:TripNode = undefined;
	/**
	 * Previous values. Only attributes that are different from snapshot will be populated
	 * Required: false
	 * Array: false
	 */
	tripNodeDiff:TripNode = undefined;
}
/**
 * 
 */
export class TripNodeActivity {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tripNodeActivitySequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	activityCd:TripNodeActivityCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	entityTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupRequestSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freightStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryInformationRqdCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualActivityDateTimeLocal:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	swingEquipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	swingTripInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cashDueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	earlyCutoffCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezeCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	headloadCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	liftgateInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	timeDateCriticalInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	appointmentInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	foodInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalBillCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPalletCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalCubePercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalMmCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Defines the specific 
	 * 
	 * type of service provided for a shipment.    Added 10/23/2019                           
	 * 
	 * 																																																	
	 * Values:  
	 * 1 - Normal LTL
	 * 2 - Con-Way Deferred Express (Deprecated)
	 * 3 - LTL Expedite Service
	 * 4 - Guaranteed by 12
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:ShipmentServiceTypeCd = undefined;
	/**
	 * Indicates that a shipment has bulk liquid.
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
}
/**
 * Holds a snapshot of TripNodeActivity. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TripNodeActivitySnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:TripNodeActivity = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:TripNodeActivity = undefined;
}
/**
 * 
 */
export class AccessorialService {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each extra service for the shipment.
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Accessorial  code
	 * 
	 * Desc : Indicates the type of accessorial service requested or performed
	 * Required: false
	 * Array: false
	 */
	accessorialCd:string = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the accessorial charges for an accessorial service . The rate is multiplied by the unit of measure to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the accessorial service.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the accessorial are the minimum charges for the extra service. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the service.
	 * 
	 * Source : MBF  Variable Segment :
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indication whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Specifies the units for the quantity for which the accessorial fee is charged.
	 * 
	 * Valid Values
	 * 1- Labour Hour
	 * 2- 1/4 hour
	 * 3- 1/2 hour
	 * 4- CWT
	 * 5- CWT/Day
	 * 6- Check
	 * 7- Correction
	 * 8- Day
	 * 9- Document
	 * A- Package
	 * B- Shipment
	 * C- Vehicle
	 * D- Mile
	 * E- Pound
	 * ' ' - Not applicable
	 * Required: false
	 * Array: false
	 */
	accessorialUnitOfMeasure:AccessorialUnitOfMeasureCd = undefined;
	/**
	 * Specifies the quantity of units (in AC_UOM) for which the accessorial fee is charged.
	 * Required: false
	 * Array: false
	 */
	accessorialQuantity:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * Holds a snapshot of AccessorialService. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class AccessorialServiceSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:AccessorialService = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:AccessorialService = undefined;
}
/**
 * 
 */
export class AdvanceBeyondCarrier {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : A system assigned number to uniquely identify each carrier for a shipment. There can be at most two other carriers for a shipment one advance and one beyond.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type movement carried out by the carrier.
	 * 
	 * 
	 * Valid Values:
	 * 1 = Advance carrier
	 * 2 = Beyond carrier
	 * Required: false
	 * Array: false
	 */
	typeCd:AdvanceBeyondTypeCd = undefined;
	/**
	 * Expanded Name: Standard Carrier Abbreviation Code
	 * 
	 * Desc : Other Carrier s identification code for Advance and Beyond movements for the shipment.
	 * 
	 * This is the identifying code for the carrier from whom CTS picked up (or transfers to) the freight. If the code is unknown, NONE is entered by the bill entry personnel. 
	 * 
	 * Source : MBF root  -  MBADSCAC
	 * Required: false
	 * Array: false
	 */
	carrierScacCd:string = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This is  the other carrier s PRO number that identifies the shipment for the carrier in their systems.
	 * Required: false
	 * Array: false
	 */
	carrierProNbr:string = undefined;
	/**
	 * Expanded Name: Carrier pick up date
	 * 
	 * Desc: This is the date the carrier picked up the shipment. 
	 * 
	 * In case of an advance carrier this is the date the carrier picked it up from the customer.
	 * 
	 * 	In case of beyond carriers, it is the date the carrier picked up the shipment from CTS.
	 * Required: false
	 * Array: false
	 */
	carrierPickupDate:string = undefined;
	/**
	 * Expanded Name : From terminal SIC
	 * 
	 * Desc : SIC where the shipment originates for the carrrier.  
	 * 
	 * For advance carriers it is the sic that services the shipper and for beyond carrier this is CTS service center that delivers teh PRO to the beyond carrier. 
	 * 
	 * This is mostly stored for Beyond carriers.
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	fromTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : To terminal SIC
	 * 
	 * Desc : This is CTS SIC where an advance carrier dropped the shipment for further movement. For beyond carrier this is the SIC code that services the consignee. 
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	toTerminalSicCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the advance or beyond charge.
	 * Required: false
	 * Array: false
	 */
	chargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AsMatchedParty {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence Number
	 * 
	 * Desc : A system assigned sequential number to uniquely identify each As Entered Customer for a Shipment.
	 * 
	 * There are always at least two customers for a shipment and atmost 6 customers for a shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : Specifies the nature of relationship of the customer with respect to the Service Item in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact. 
	 * 
	 * Permitted Values:
	 * 										1=Shipper address
	 * 										2=Consignee address 
	 * 										3=Inbound bill-to address 
	 * 										4=Outbound bill-to address
	 * 										5=Requestor address
	 * 										6=Contact address 
	 * 
	 * Source : Internal
	 * Required: false
	 * Array: false
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : Debtor indicator
	 * 
	 * Desc : Specifies whether the customer is also responsible for paying some or all of the charges for a Service Item. Invoices, allocations (if any) are created only for debtors. 
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	debtorInd:boolean = undefined;
	/**
	 * Expanded Name : Name Line 1 (text format)
	 * 
	 * Desc : Line 1 of the customer name
	 * 
	 * Source : MBF variable segments
	 * 													AA  Shipper segment
	 * 													AB  Consignee segment
	 * 													AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	name1:string = undefined;
	/**
	 * Expanded Name : Name Line 2 (text format)
	 * 
	 * Desc : Second line (if any) of customer name
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	name2:string = undefined;
	/**
	 * Expanded Line : Address 
	 * 
	 * Desc : Street address of the customer location.
	 * 
	 * Source: MBF variable segments
	 * 	AA  Shipper segment
	 * 	AB  Consignee segment
	 * 	AC  Bill to segment
	 * Required: false
	 * Array: false
	 */
	address:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : City where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State (code)
	 * 
	 * Desc : State where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * Expanded Name : Country Code
	 * 
	 * Desc : Specifies the code of the country where the customer is located
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * Expanded Name : Zip (code) first 5 (characters) (6 characters for Canadian zips)
	 * 
	 * Desc : First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the customer is located. 
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip6:string = undefined;
	/**
	 * Expanded Name : Zip (Code) last 4 (characters)
	 * 
	 * Desc : Last 4 characters of the zip code for a US address
	 * 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 * Required: false
	 * Array: false
	 */
	zip4RestUs:string = undefined;
	/**
	 * Expanded Name : Matching Status
	 * 
	 * Desc : Match status from CIS matching.
	 * 
	 * Valid Values :
	 * 
	 * The following values ARE ACTIVE for Forward Sync:
	 * 
	 * Spaces - Initial value, matching activity has not yet occurred.  
	 * 	70  - Full Match 
	 * 	60  - Un-matched (valid name / address but does not exist in CIS)
	 * 
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * 
	 * 	15  - Possible matches found
	 * 	30  - Invalid address (Bad US postal address as per Code-1)
	 * 	50  - Blank bill2 - no bill-to was entered
	 * 
	 * The following was added by C.Doucet 10/12/2001:
	 * 
	 * 	CM  - CIS Customer Merge correction changed the CIS cust nbr value
	 * Required: false
	 * Array: false
	 */
	matchedStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Expanded Name : Matcher s Initials 
	 * 
	 * Desc : Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.  
	 * 
	 * Source : Extended Master Bill File. These initials are the same as those stored on the EMB (JABCOB).
	 * Required: false
	 * Array: false
	 */
	matchedInitials:string = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * 
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 * Required: false
	 * Array: false
	 */
	cisCustNbr:number = undefined;
	/**
	 * Expanded Name : As-Matched MAD code
	 * 
	 * Desc : Customer Account code.  This is the MAD code that came from CIS at the time of Customer Matching.
	 * 
	 * Source : Customer Information System
	 * Required: false
	 * Array: false
	 */
	asMatchedMadCd:string = undefined;
	/**
	 * Expanded Name : Direct code
	 * 
	 * Desc : Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 * 
	 * This is a de-normalisation as direct code is available only for CIS customers and it should be picked up from appropriate CIS table. 
	 * 
	 * Source : MBF: root: MBSCUST
	 * Required: false
	 * Array: false
	 */
	dirCd:string = undefined;
	/**
	 * Expanded Name : Credit Status Code
	 * 
	 * Desc : Specifies the credit rating / status for a Customer, if not set up in CIS. If the customer exists in CIS, it is copied here for performance reasons.
	 * 
	 * Source : MBF: root: MBONOST (Out-bound)
	 * 														MBF: root: MBINOST (In-bound)
	 * 
	 * 
	 * Valid Values : 
	 * 
	 * B=Bad debt/MBOBAD
	 * C=Credit/MBOCRED
	 * N=Non-credit/MBONOCRD
	 * P=PCO Control/MBOPCO
	 * V=Voluntary/MBOVOLON
	 * $=Cash only/MBOCASH
	 * Required: false
	 * Array: false
	 */
	creditedStatusCd:ShipmentCreditStatusCd = undefined;
	/**
	 * Expanded Name:  Bill to Relationship Code
	 * 
	 * Desc: Indicates who the Bill to customer is related to (Shipper or Consignee) in the CIS system. This field applies only to BILL TO type of customers. 
	 * 
	 * Valid Values:
	 * 				- Not Applicable
	 * S - Bill To is related to Shipper
	 * C - Bill To is related to Consignee
	 * B - Bill To is related to Both
	 * N - Bill To is related to None
	 * Required: false
	 * Array: false
	 */
	billToRelationshipCd:BillToRelationCd = undefined;
	/**
	 * Expanded Name : Use as entered indicator
	 * 
	 * Desc : 
	 * 
	 * Source : E-commerce
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	useAsEntrdInd:boolean = undefined;
	/**
	 * Expanded Name : All shipments (must be) prepaid indicator
	 * 
	 * Desc : Specifies whether all shipments from the customer have to be pre-paid
	 * 
	 * Source : MBF: root: MBR4PPDA (x 02  of MBRGFLG4)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	allShipmentPrepaidInd:boolean = undefined;
	/**
	 * Expanded Name :   Proof Of Delivery  required indicator
	 * 
	 * Desc : Indicates whether the customer (usually shipper and / or consignee) requires a Proof Of Delivery.
	 * 
	 * Source : MBF: root: MBSPD (x 80  of MBFLAGD)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	proofOfDeliveryRequiredInd:boolean = undefined;
	/**
	 * Expanded Name :  Proof Of Delivery  image indicator
	 * 
	 * Desc : Specifies that an image of the delivery receipt will suffice, instead of an original 
	 * 
	 * Source : MBF: root: MBSMG (x 40  of MBFLAGD)
	 * 
	 * Valid values : Y/N
	 * Required: false
	 * Array: false
	 */
	proofOfDeliveryImageInd:boolean = undefined;
	/**
	 * Expanded Name : Broker customer key number
	 * 
	 * Desc : For international shipments, identifies the CIS customer number of any broker for the shipper or consignee. 
	 * 
	 * Source : MBF: root: MBBRKKEY
	 * Required: false
	 * Array: false
	 */
	brokerCustKeyNbr:number = undefined;
	/**
	 * Expanded Name :  Customer E-mail Id
	 * 
	 * Desc : E-mail address of the Customer on a bill, used as supplemental information in case that customer needs to be contacted via e-mail.
	 * Required: false
	 * Array: false
	 */
	eMailId:string = undefined;
	/**
	 * Expanded Name : Phone Country Code 
	 * 
	 * Desc : Country code for the phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneCountryCdNbr:string = undefined;
	/**
	 * Expanded Name :  Phone Area Code 
	 * 
	 * Desc : Area Code for phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneAreaCdNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone 
	 * 
	 * Desc : Phone number of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone Extension Number
	 * 
	 * Desc : Phone Extension of the customer (if any) for a Shipment. 
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	phoneExtensionNbr:string = undefined;
	/**
	 * Expanded Name : Last Match Timestamp
	 * 
	 * Desc : Identifies the last time Customer Matching was performed against this particular customer for this Service Item.
	 * 
	 * Source : MBF root: MBTMDTE
	 * Required: false
	 * Array: false
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded Name: Preferred Payment Currency Code
	 * 
	 * Desc: The customer prefers to make payments in this currecny.
	 * 
	 * Valid Values:
	 * 
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	preferredPaymentCurrencyCd:CurrencyCd = undefined;
	/**
	 * Expanded Name: Alternate Customer Number
	 * 
	 * Desc: Contains the customer key to an uncoded customer in the customer component.  These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system.  (Note that they are not currently stored in the P  table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 * 
	 * OR
	 * Contains the non-debtor P location where there were multiple full matches (89% and higher) at the same perentage.  (Changed meaning of attribute 3/1/2010)
	 * 
	 * Added 2/2/2010 Cathy Doucet (for Hand Held Scorecard project)
	 * Required: false
	 * Array: false
	 */
	alternateCustNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Identifies whether the system full matched the party, or if the Biller chose from a list of possibles. 
	 * 
	 * Valid Values 
	 * S=System Matched
	 * U=User Selected
	 * F=User Selected from Search
	 * N=Not matched
	 * Required: false
	 * Array: false
	 */
	matchedSourceCd:MatchedPartySourceCd = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self) versus from an associated Bill To.
	 * Required: false
	 * Array: false
	 */
	selfInvoiceInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of AsMatchedParty. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class AsMatchedPartySnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:AsMatchedParty = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:AsMatchedParty = undefined;
}
/**
 * 
 */
export class BolUsage {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolBillingVersionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:BolUsageTypeCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CommodityDimension {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * Expanded Name: Sequence Number
	 * 
	 * Desc: A system generated sequential number to uniquely identify the recorded dimensions for a shipment.
	 * Required: false
	 * Array: false
	 */
	dimSequenceNbr:string = undefined;
	/**
	 * Expanded Name: Pieces Count
	 * 
	 * Desc: This is the number of pieces the recorded dimensions apply to.
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Length                               
	 * 																																																				
	 * Description: This is the length of the whole or part
	 * of the shipment. The number of pieces attribute     
	 * indicates how many pieces of the shipment the       
	 * dimensions apply to.
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * Expanded Name: Width                                   
	 * 																																																							
	 * Description: This is the width of the whole or part of 
	 * the shipment. The number of pieces attribute indicates 
	 * how many pieces of the shipment the dimensions apply   
	 * to.
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * Expanded Name: Height                                
	 * 																																																								
	 * Description: This is the height of the whole or part of  
	 * the shipment. The number of pieces attribute indicates  
	 * how many pieces of the shipment the dimensions apply    
	 * to.
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Commodity {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : A system assigned number to uniquely identify each commodity for a shipment.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : (Commodity) class
	 * 
	 * Desc : Identifies the type (class) of commodity being shipped.
	 * 
	 * Examples:  Carpet, Matresses, Computer paper
	 * 
	 * Valid Values : 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 350, 400, 500  
	 * 
	 * Source: MBF Variable Segment : MBVDESC
	 * Required: false
	 * Array: false
	 */
	classType:CommodityClassCd = undefined;
	/**
	 * Expanded Name : NMFC item code
	 * 
	 * Desc : This is the NMFC (National Motor Freight Classification) code for the commodity.
	 * 
	 * Source: MBF Variable Segment - MBNMFC
	 * Required: false
	 * Array: false
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * 
	 * Desc : Code indicating the source of a particular Commodity Line.  Provides a way to distinguish between a Commodity Line on a bill that originated from the Common Shipper Bill of Lading (CSBOL) profile or a new Commodity Line that is added onto a bill where CSBOL Commodity Lines already exist.
	 * 
	 * Source : FBES E-Commerce
	 * 
	 * Valid Values :
	 * 1 - from CSBOL
	 * 2 - Not from CSBOL
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Expanded Name: Packaging code
	 * 
	 * Desc : Type of packaging the commodity was originally picked up in. 
	 * 
	 * Examples: Box, Skid, Case, Roll etc.
	 * 
	 * Source: MBF Variable Segment - MBVDESC
	 * Required: false
	 * Array: false
	 */
	packageCd:CommodityPackageCd = undefined;
	/**
	 * Expanded Name : Pieces count
	 * 
	 * Desc : Number of pieces of the commodity in the shipment.
	 * 
	 * Source : MBF Variable Segment - MBPIECES
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; the height multiplied by the width multiplied by the depth
	 * 
	 * Source :
	 * Required: false
	 * Array: false
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total weight (in pounds)
	 * 
	 * Desc : Total weight of the commdity in pounds. 
	 * 
	 * Source: MBF Root - MBTOTWT
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * Expanded Name : Reduced weight
	 * 
	 * Desc : This is the weight deducted from the actual weight of the commodity to get the net weight. This weight is normally the pallet or the container weight. This part of the weight gets a free ride.
	 * 
	 * Source : MBF Variable Segment - MBWT
	 * Required: false
	 * Array: false
	 */
	reducedWeight:number = undefined;
	/**
	 * Expanded Name : Rating Unit of Measure
	 * 
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the most common unit of measure for commodities.
	 * 
	 * Valid Values : 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	ratingUnitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * 
	 * Desc : Quantity (measured in terms of UOM) to be used when calculating charges for the commodity.
	 * Required: false
	 * Array: false
	 */
	ratingQuantity:number = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * 
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous material indicator
	 * 
	 * Desc : Indicates whether the commodity is a hazardous material. 
	 * 
	 * Source : MBF Variable Segment : MBNMHMX
	 * 
	 * Valid Values :
	 * Y - Has hazardous materials
	 * N - No hazardous materials
	 * Required: false
	 * Array: false
	 */
	hazardousMtInd:boolean = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the total freight charges for a commodity. The rate is multiplied by the unit of measure (weight in most cases) to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge for the commodity.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the commodity. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Mixed class commodity exists indicator
	 * 
	 * Desc :
	 * 
	 * Source : MBF: root: MBMXCLS (x 01  of MBFLAGA)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	mixClassCommodityInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of the commodity
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	asRatedClassCd:CommodityClassCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalDescription:string = undefined;
	/**
	 * Indicates that the biller selected to have the commodity class default to 100 during bill entry since the class was not listed on the Bill of Lading.  This is to distinguish between commodity lines that actually have a true commodity class of 100 and commodity lines where the default situation occurs.  Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	defaultClassSelectedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazMat:HazMat = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	commodityDimension:CommodityDimension[] = undefined;
}
/**
 * 
 */
export class CsrComment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comment:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomsBond {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : System generated sequence number assigned to each instance to uniquely identify each customs bond information for a shipment
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Bond number text
	 * 
	 * Desc : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 * 
	 * Source: MBF  Variable Segment - MBITNUM
	 * Required: false
	 * Array: false
	 */
	bondNbr:string = undefined;
	/**
	 * Expanded Name : City
	 * 
	 * Desc : Name of the City where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 * Required: false
	 * Array: false
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State
	 * 
	 * Desc : State of the city where the customs inspection is to be made.
	 * 
	 * Source: MBF Variable Segment - MBINBOND
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomsControl {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each customs control for a Shipment.
	 * 
	 * Source : Generated
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Customs type code
	 * 
	 * Desc : Indicates the type of customs crossing by identifying the two different countries involved. 
	 * 
	 * Source :
	 * 
	 * Valid Values : 
	 * 
	 * 1 - Shipment crossing from the US to Canada (Canadian 
	 * 					customs information) 
	 * 2 - Shipment crossing from Canada to the US (US
	 * 					customs information) 
	 * 3 - Shipment crossing from the US to Mexico (Mexican
	 * 					customs information)
	 * 4 - Shipment crossing from Mexico to the US (US
	 * 					customs information)
	 * Required: false
	 * Array: false
	 */
	lineTypeCd:CustomsControlTypeCd = undefined;
	/**
	 * Expanded name: CSA status indicator
	 * 
	 * Desc: Code that indicates whether this shipment is a CSA approved shipment.
	 * 
	 * Valid values:
	 * Y = Yes the shipment is a CSA shipment
	 * N = No the shipment is not a CSA shipment
	 * 
	 * *Note: CSA stands for Customer Self Accessment.  This is a Canadian Customs program that identifies shipments inwhich all of the parties involved are  trusted  by Canada.  Con-Way and certain of it s drivers are certified in the program.  The importer must also be certifed in the program.  However, not all shipments that have all certifed parties are CSA shipments.
	 * Required: false
	 * Array: false
	 */
	csaStatusInd:boolean = undefined;
	/**
	 * Expanded name: CSA status yes indicator
	 * 
	 * Desc: Code that indicates whether this shipment ever had the CSA status indictor set to YES.  This is for audit purposes.
	 * 
	 * Valid values:
	 * Y = Yes the shipment is or has been marked as CSA
	 * 						approved.
	 * N = No the shipment has never been marked as CSA
	 * 						approved.
	 * Required: false
	 * Array: false
	 */
	csaStatusYesInd:boolean = undefined;
	/**
	 * Expanded name: CSA Number Text
	 * 
	 * Desc: Number that the Canadian Customs agency assigns to  an importer.
	 * Required: false
	 * Array: false
	 */
	csaNbr:string = undefined;
	/**
	 * Expanded name: Port Code
	 * 
	 * Description: This is a code supplied by the specified customs agency that identifies the location that shipments will cross the border.
	 * Required: false
	 * Array: false
	 */
	portCd:CustomsPortCd = undefined;
	/**
	 * Expanded name: Border Crossed Indicator
	 * 
	 * Desc: Flag that indicates if this shipment has crossed this border yet.  Currently we only know that when the arrive happens.  With handhelds we may be able to know this sooner.
	 * Required: false
	 * Array: false
	 */
	borderCrossedInd:boolean = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class DeliveryDateAudit {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	combSrvcTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	standardTransitDays:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalDeliveryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	newDeliveryDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originalTransitDays:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	newTransitDays:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmnt:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EventLog {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:EventLogTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	subTypeCd:EventLogSubTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	programId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	occurredDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:ArchiveControlCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trlrIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trlrIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	occurredSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	enrouteInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil21StCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bil22NdCustNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCountryCd:CountryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCountryCd:CountryCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	adminInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billTo1StRelationshipCd:BillToRelationCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	adminStatusCd:BillStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billTo2NdRelationshipCd:BillToRelationCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ediSenderShipmentId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	debtorCd:DebtorCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	purInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	bolInstId:number = undefined;
	/**
	 * A unique identifier to correlate multiple transactions. This can be used by a log aggregator to tie events from multiple technologies such as UI, Middle ware and integration.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	thirdPartyInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Surrogate key for SHM_EVENT_LOG.  Added 02/11/2020 for Project Radar (Saravanan)
	 * Required: false
	 * Array: false
	 */
	shipmentEventLogId:string = undefined;
}
/**
 * Holds a snapshot of EventLog. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class EventLogSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:EventLog = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:EventLog = undefined;
}
/**
 * 
 */
export class HazMat {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatSequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sourceCd:HazmatSourceCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatUnna:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatWeightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatBulkQuantityCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatResidueInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zoneCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	packingGroupCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideMethodNm:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dotSpecialPermit:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	limitedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportedQuantityInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	explosiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	netExplosiveMassNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	netExplosiveMassUnitOfMeasure:NetExplosiveMassUomCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	marinePollutantInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	marinePollutantChemical:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactiveChemicalName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactivityNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	radioactivityUnitOfMeasure:RadioactivityUomCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	transportIndex:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fissileExceptedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emergencyContactPhoneNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	technicalName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unnaInstId:number = undefined;
}
/**
 * Holds a snapshot of HazMat. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class HazMatSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:HazMat = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:HazMat = undefined;
}
/**
 * 
 */
export class HazmatCdRef {
	/**
	 * Expanded Name : UN Number Text
	 * 
	 * Desc : United Nations Identification Number.
	 * Required: false
	 * Array: false
	 */
	unNbr:string = undefined;
	/**
	 * Expanded Name : Packing Group Code
	 * 
	 * Desc : Indicates degree of danger presented by material.
	 * 
	 * Valid Values:
	 * 'I' = great degree of danger
	 * 'II' = medium degree of danger
	 * 'III' = minor degree of danger
	 * blank = not applicable
	 * Required: false
	 * Array: false
	 */
	packingGroupCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Class Code
	 * 
	 * Desc : Hazardous Materials Classification Code.
	 * Required: false
	 * Array: false
	 */
	hazmatClassCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Level Code
	 * 
	 * Desc : Hazardous Materials Level Code.
	 * 
	 * Valid Values:
	 * 0 = Hazardous
	 * 1 = Extremely Hazardous
	 * Required: false
	 * Array: false
	 */
	hazmatLevelCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Zone Code
	 * 
	 * Desc : Hazardous Materials Zone Code.
	 * 
	 * Valid Values:
	 * A = Hazard Zone A
	 * B = Hazard Zone B
	 * C = Hazard Zone C
	 * D = Hazard Zone D
	 * blank = Not Applicable
	 * Required: false
	 * Array: false
	 */
	hazmatZoneCd:string = undefined;
	/**
	 * Expanded Name : Description Text
	 * 
	 * Desc : Hazardous materials descriptions and proper shipping names.
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name: Restricted indicator                                                            
	 * 
	 * Desc:  Y  indicates a class of hazardous material that Con-way has chosen not to transport.
	 * Required: false
	 * Array: false
	 */
	restrictedInd:boolean = undefined;
	/**
	 * Expanded Name:  Class label          
	 * 
	 * Desc: The DOT combined hazard class codes listed in order of importance required for labeling hazardous material.
	 * Required: false
	 * Array: false
	 */
	classLabel:string = undefined;
	/**
	 * Expanded Name:  Over-ride method name                        
	 * 
	 * Desc: The override class code used by the Placarding wizard.  Under normal circumstances the HZMT_CLASS_CD is used by the Placarding wizard for its method call.  This attribute will only be populated when the HZMT_CLASS_CD contains the incorrect method for the Placarding wizard.
	 * Required: false
	 * Array: false
	 */
	overrideMethodNm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	unSequenceNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InventoryTracking {
	/**
	 * Expanded Name : Instance identifier
	 * 
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * 
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * 
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * 
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * 
	 * MBF: root MBPRO
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Expanded Name : Status Code
	 * 
	 * Desc : Self-documenting value that describes the status of the Shipment from an Inventory Tracking perspective.
	 * 
	 * VALID VALUES
	 * BIL= Billed
	 * RES=Reset
	 * PRG=Purged
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentTrackingNbr:number = undefined;
	/**
	 * Expanded Name : Tracking Exception Code
	 * 
	 * Desc: Tracks discrepancies between SHM_INVENTORY_TRACKING and SHM_SHIPMENT. 
	 * 
	 * VALID VALUES
	 * OREP=Overage Report Bill
	 * Required: false
	 * Array: false
	 */
	trackingExceptionCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LnhDimension {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dimensionsSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stackableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	capturedByUserid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	capturedByDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	capturedByCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class MiscLineItem {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Line type code
	 * 
	 * Desc : Indicates the type of  line item. 
	 * 
	 * Source : MBF Variable Segment - MBWEIGHT
	 * 
	 * Valid Values : 
	 * 
	 * 1 - As weight 
	 * 2 - Deficit weight 
	 * 3 - COD Amount
	 * 4 - Discount line (Reduced charge)
	 * 5 - Part PPD Line
	 * 6 - Part Collect Line
	 * 7- Cash Prepaid Line
	 * 8 - Cash Collected line
	 * 
	 * Note: As weight - Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated.
	 * Required: false
	 * Array: false
	 */
	lineTypeCd:MiscLineItemCd = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * 
	 * Desc : This is the rate used to calculate the  charges for the line item. Not all line items have a charge associated with them. The rate when present is multiplied by the unit of measure  to calculate the total charges. 
	 * 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 * Required: false
	 * Array: false
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name : Unit of Measure
	 * 
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the unit of measure for the AS WEIGHT and  DEFICIT WEIGHT line items. Not all line items have an UOM. 
	 * 
	 * Valid Values :
	 * space - Where UOM is n/a to the Charge. 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * 
	 * Desc : Quantity (measured in terms of UOM) to be used in calculating charges
	 * 
	 * It represents As Weight on an AS WEIGHT line. Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated. 
	 * 
	 * For some Charge lines where it does not make sense to have a QTY, such as a COD, zeros may be formatted; UOM must be a space in this case.
	 * 
	 * Source: MBF Variable Segment - MBWEIGHT
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * 
	 * Desc : The dollar amount of the charge associated with the line item.
	 * Required: false
	 * Array: false
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * 
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 * Required: false
	 * Array: false
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * 
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the line item. 
	 * 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * 
	 * Valid Values :  Y/N
	 * Required: false
	 * Array: false
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name :  Description
	 * 
	 * Desc : A textual description for the line item.
	 * 
	 * Source : MBF  Variable Segment :
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * 
	 * Desc : Sequential number assigned to uniquely identify  each miscelleneous charge for a Shipment. This entity stores all the differnt types of charge lines that appear on the invoice for the shipment. Not all of these lines contribute to the CTS revenue.Some examples are COD, As Weight, Deficit Weight lines etc. 
	 * 
	 * Source : Generated
	 * 
	 * Design Considerations :
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	paymentMethodCd:MiscLineItemPaymentMethodCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	checkNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * 
 */
export class Movement {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementReportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLoadedSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rshpCreditedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scacCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdById:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	qlfrReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	customerLocationArrivalDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movementException:MovementException[] = undefined;
}
/**
 * Holds a snapshot of Movement. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class MovementSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Movement = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Movement = undefined;
}
/**
 * 
 */
export class MovementException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:MovementExceptionTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rfsdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	undlvdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmgdCategoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	createdUserId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exceptionReportingSicCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movementExceptionAction:MovementExceptionAction[] = undefined;
}
/**
 * Holds a snapshot of MovementException. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class MovementExceptionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:MovementException = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:MovementException = undefined;
}
/**
 * 
 */
export class ShmNotification {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	notificationSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scoNtficnInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	categoryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reschdReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryFromTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeRefNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryToTime:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callerRacfId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	callDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxuser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxaction:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of ShmNotification. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ShmNotificationSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:ShmNotification = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:ShmNotification = undefined;
}
/**
 * 
 */
export class Remark {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name :  Type code
	 * 
	 * Desc : This code can be used to categorize the remarks.  
	 * 
	 * Valid Values : 
	 * 1 - Adhoc Hazardous Material Remarks
	 * 2 - Shipping Remarks
	 * 3 - Authority Line text Remarks
	 * 4 - Delivery Attachment Remarks
	 * 5 - Operational Freight Handling Remarks
	 * 6 - Final Delivery Date Correction Remarks
	 * 
	 * Source: New/Entered
	 * 
	 * Design Considerations : This can be used for subsetting purposes and for programatically pulling certain remarks to display and/or print.
	 * Required: false
	 * Array: false
	 */
	typeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Expanded Name: Remark Text
	 * 
	 * Desc: Remark Text
	 * Required: false
	 * Array: false
	 */
	remark:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of Remark. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class RemarkSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Remark = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Remark = undefined;
}
/**
 * 
 */
export class RatingInformation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rulesetOwnerCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperAgreementId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperRulesetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeAgreementId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeRulesetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToAgreementId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billToRulesetNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	defaultTariffsId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingTariffsId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateBaseName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateBaseVersNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tariffTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	applcblDiscountPercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualDsctPercentage:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateAuditorInitials:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateOrRateAudtqName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rateQueTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	classOverrideCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	multiClassInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	rulesTariffsId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actualFuelPriceAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fuelPriceEffDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fscRateUnitOfMeasure:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fscRateNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fscOverrideInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specialRatingProgramId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	manualRatedInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	couponCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	excRate:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpLnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpTotalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalAccessorialAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpAccessorialAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalTaxAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpTaxAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratedAsWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	corpAmcInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	codAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ctsRevenueAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ctsRevenueAmountInUsd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalChargeAmountInUsd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	invoiceCurrencyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpMaxChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpBaseChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpFactorNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpPersonMileRate:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpTrailerCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tlpSavingsAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	diLnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	diFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reducedWeight:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfTotalChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfLnhlChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfFscAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cctrfAmcInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingOriginStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingOriginZipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingDestinationStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingDestinationZipCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	totalObiDscntAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	ratingTariffsRbn:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstyLnhChargeAmount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstyCalculatedClassCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstySrcTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	chargeToCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCisCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCustomerMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipperZip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCisCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCustomerMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	consigneeZip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCisCustomerNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCustomerMadCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoName1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoName2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoStateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	billtoZip6:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnstyTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elsLengthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	elsLengthUnitOfMeasure:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ShipmentCommodityAssn {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	commodityInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TimeDateCritical {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded: Time Date Critical Source Code
	 * 
	 * Desc: Where the Time Date Critical information was entered.
	 * 
	 * Valid Values:
	 * BIL=Bill Entered
	 * PUR=Pick Up Request
	 * EDI=EDI
	 * WEB=Web
	 * COR=Corrections
	 * Required: false
	 * Array: false
	 */
	tdcSourceCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date Type Code
	 * 
	 * Desc: What kind the dates on the TDC shipment is/are.  There are 3 main flavors of date on Time Date Critical shipments: Deliver on a date, Deliver by a date, or a Deliver sometime within a date range  
	 * 
	 * Valid Values:
	 * ON=On
	 * BY=By
	 * RNG=Range
	 * Required: false
	 * Array: false
	 */
	tdcDateTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #1
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the following flavor of date: ON, BY or FROM
	 * Required: false
	 * Array: false
	 */
	tdcDate1:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #2
	 * 
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the TO date if the type is a date range.
	 * Required: false
	 * Array: false
	 */
	tdcDate2:string = undefined;
	/**
	 * Expanded: Time Date Critical Time Type
	 * 
	 * Desc: What kind the times on the TDC shipment is/are.  There are 4 main flavors of time for Time Date Critical shipments: Deliver at a time, Deliver before a time, Deliver after a time, or a Deliver sometime within a time range  
	 * 
	 * Valid Values:
	 * AT=At
	 * BEF=Before
	 * AFT=After
	 * RNG=Range
	 * Required: false
	 * Array: false
	 */
	tdcTimeTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #1
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the following flavor of time: AT, AFTER, BEFORE, FROM
	 * Required: false
	 * Array: false
	 */
	tdcTime1:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #2
	 * 
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the TO time if the type is a time range.
	 * Required: false
	 * Array: false
	 */
	tdcTime2:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Holds a snapshot of TimeDateCritical. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class TimeDateCriticalSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:TimeDateCritical = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:TimeDateCritical = undefined;
}
/**
 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.  Added 01/02/2020
 */
export class MovementExceptionAction {
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * Part of unique identifier
	 * Required: false
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
	/**
	 * Uniquely identifies a row
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * Responsible sic could be determined to be different from what SHM_movement has recorded
	 * Required: false
	 * Array: false
	 */
	responsibleSicCd:string = undefined;
	/**
	 * Describes what corrective action XPO has taken to address the exception. This is typically shared with the customer.
	 * Required: false
	 * Array: false
	 */
	correctiveAction:string = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Indicates whether the carrier was in a position to control or prevent the exception.  Added 03/23/2020 LISG-4722
	 * Required: false
	 * Array: false
	 */
	controlInd:boolean = undefined;
}
/**
 * Holds a snapshot of MovementExceptionAction. Contains the details at the time on an event. It also provides the previous values if an update occured
 * Describes the action that is being taken for a shipment exception as well as the Service Center that is responsible for taking the action and the reason for the action.  Added 01/02/2020
 */
export class MovementExceptionActionSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:MovementExceptionAction = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:MovementExceptionAction = undefined;
}
/**
 * 
 */
export class HandlingUnit {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trackingProNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentProNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	weightLbs:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lengthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	widthNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	heightNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentTrailerInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentDockLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stackableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:HandlingUnitTypeCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	handlingUnitMovement:HandlingUnitMovement[] = undefined;
}
/**
 * Holds a snapshot of HandlingUnit. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class HandlingUnitSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:HandlingUnit = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:HandlingUnit = undefined;
}
/**
 * 
 */
export class HandlingUnitMovement {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementReportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockInstanceId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class SuppRefExtendedDetail {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name: Packaging Type Text
	 * 																																																							
	 * Desc : Type of packaging the commodity was originally    
	 * picked up in.                                          
	 * 																																																							
	 * Examples: Box, Skid, Case, Roll etc.                   
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	packageType:string = undefined;
	/**
	 * Expanded Name : Unit of Measure                             
	 * 																																																												
	 * Desc : A code to identify the units in terms of which       
	 * the quantity is measured. For       
	 * eg. weight (in pounds) is the most common unit of           
	 * measure for commodities.                                    
	 * 																																																												
	 * Valid Values :                                              
	 * 1 - Weight (in pounds)                                      
	 * 2 - Yardage (for carpets)                                   
	 * 3 - Count
	 * Required: false
	 * Array: false
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantify
	 * 
	 * Desc : Commodity line item quantify associated with this specific supplemental reference number.  Usually expressed in pounds (reference the UOM - Unit of Measure to verify).
	 * Required: false
	 * Array: false
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name : Pieces Count
	 * 
	 * Desc : Commodity line pieces associated with this specific supplemental reference number.
	 * Required: false
	 * Array: false
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Pallet or Slip Indicator
	 * 																																																							
	 * Desc : Used on a VICS bill of lading to indicate that the freight at time of pickup was loaded on either a pallet or a slip sheet.
	 * 
	 * Valid Values:  Y/N
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	palletOrSlipInd:boolean = undefined;
	/**
	 * Expanded Name: Additional Shipper Information
	 * 																																																							
	 * Desc : Contains additional comments supplied from the shipper, usually regarding how the freight should be handled.
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 * Required: false
	 * Array: false
	 */
	additionalShipperInformation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Table captures comments by a Customer care person managing key accounts regarding reason and resolution of movement exceptions. This information is sent proactively to the customer. The goal is to keep customer happy.  
 * 10/24/2019 This table now stores remarks for Shipments as well as Movement Exceptions.  PK has to be changed.
 */
export class ManagementRemark {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementSequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	movementExceptionSequenceNbr:number = undefined;
	/**
	 * Remark entered by the customer care person, describing the exception. This is typically shared with the customer.
	 * Required: false
	 * Array: false
	 */
	remark:string = undefined;
	/**
	 * Remarks in this table will be of type:
	 * Customer Service Remarks
	 * Exception Remarks
	 * 11/14/2019 Renamed from REASON_CD to TYPE_CD
	 * Required: false
	 * Array: false
	 */
	typeCd:ShipmentManagementRemarkTypeCd = undefined;
	/**
	 * Y- default. The data is shown to the external customer. N- These comments are for XPO internal documentation and will not be shown to the customer.
	 * Required: false
	 * Array: false
	 */
	showToCustomerInd:boolean = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
}
/**
 * This has one row per shipment, and contains operational data for the PRO.  Added 01/22/2020
 */
export class OperationsShipment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Indicates the priority of the shipment. Initially set when shipment is created. High values indicate lower priority, lower values indicate higher priority. This will be recalculated when any property that determines priority is changed.
	 * Required: false
	 * Array: false
	 */
	priorityNbr:number = undefined;
	/**
	 * Calculated ETA by BI and updated; it is recalculated every 5 minutes.
	 * Required: false
	 * Array: false
	 */
	destinationSicEtaDateTime:Date = undefined;
	/**
	 * Another way to set priority of a shipment; it is set by users through UI.
	 * Required: false
	 * Array: false
	 */
	hotShipmentInd:boolean = undefined;
	/**
	 * Calculated any time event comes in, this will be recalculated. E.g. if one of the agenda list items 'NOT_MET'
	 * Required: false
	 * Array: false
	 */
	scheduledDeliveryDateTime:Date = undefined;
	/**
	 * Following will set the value: Final value is set when PRO arrives at dest sic.  Customer might ask for specific time (not TDC, not entered at billing). There might be other cases where this might be set.
	 * Required: false
	 * Array: false
	 */
	customerExpectedDeliveryDateTime:Date = undefined;
	/**
	 * After the shipment is final delivered. Same as in SHM_MOVEMENT (DLVRY QLFR=Z)
	 * Required: false
	 * Array: false
	 */
	actualDeliveryDateTime:Date = undefined;
	/**
	 * A unique identifier to correlate multiple Invoices. This is used as a batch# when reprint for Email is requested and they need to be processed as a group.
	 * 	This is a value that API gateway assigns for a call. API then loops through creating multiple invoices with the same value
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Shipment {
	/**
	 * Expanded Name : Instance identifier
	 * 
	 * Desc : System generated unique identifier (as per CBD standard).
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code 
	 * 
	 * Desc : Identifies the type of shipment.
	 * 
	 * 
	 * Valid Values:
	 * A = Normal Movement(NORM)
	 * B = Master Shipment(MSTR) 
	 * C = Segment of a Master shipment (MSEG)
	 * D = Revenue Bill for Split segments (PTLT)
	 * E = Partial Shipment Segment (PSEG)
	 * G = Claims overage Reporting Bill (OREP)
	 * H = Genral Claims Business Shipment (GCBZ)
	 * I =  Astray Freight Segment (MOVR)
	 * J = Company Business Shipment (COBZ) 
	 * K = Expedite
	 * 
	 * 
	 * Note:
	 * Overage Reporting Bill (OREP) is entered into the system just  to indicate to the claims system there is an overage shipment on dock. 
	 * 
	 * Astray freight(MOVR) is used to move freight to it s correct destination after it accidentally ended up somewhere else and its original PRO number is known.
	 * Required: false
	 * Array: false
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * 
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * 
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * 
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * 
	 * MBF: root MBPRO
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Bill Status Code
	 * 
	 * Desc : Current status of the Shipment from billing point of view. 
	 * 
	 * Valid Values:
	 * 1 - Unbilled 
	 * 2 - In FBES Suspense Queue
	 * 3 - Billed
	 * 4 - Rated
	 * 5 - Paid
	 * 6 - Cancelled
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Expanded Name :  Charge to code
	 * 
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * 
	 * Valid Values : 
	 * 	P    -  Prepaid - shipper responsible for all charges
	 * 	C    -  Collect - consignee responsible for all charges
	 * 	B    -  Both - shipper and consignee responsible 
	 * 								-  Valid only for Non-Revenue bills.
	 * 			
	 * Source: MBF : MBPRTPPD, MBPRTCOL
	 * Required: false
	 * Array: false
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Expanded Name : Original terminal SIC
	 * 
	 * Desc : SIC where the Shipment originates.  Usually associated with PRO prefix and can be overriden.
	 * 
	 * Source : MBF: root MBORGIN
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Destination terminal SIC
	 * 
	 * Desc : SIC that is scheduled to deliver the Shipment. 
	 * 
	 * Source : MBF: root MBDEST
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Current SIC
	 * 
	 * Desc : SIC where the Shipment currently is.  
	 * 
	 * This is a denormalization to avoid scanning all movements to find current location.
	 * 
	 * Source : MBF: root MBLOCX
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * 
	 * Desc : Indicates the source or origination of the Pro.  This code is needed for ACOR processing to determine whether to roll back to the initial data.
	 * 
	 * Valid Values:
	 * 	1  - Web        (Pickup Request web page)
	 * 	2  - EDI        (set 204 or set 211)    
	 * 	3  - FTP        (file transfer)
	 * 	4  - FBES    (manual Bill Entry)
	 * 	5  - TCON or LOAD    (i.e. skeleton TCON or non-TCON LOAD)
	 * 	6  - Pickup Request
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	sourceCd:ShipmentSourceCd = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Expanded Name : Ready Time                        
	 * 																																																		
	 * Desc : Earliest available time that freight can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	readyTime:string = undefined;
	/**
	 * Expanded Name : Close Time                      
	 * 																																																
	 * Desc : Latest available time that freight can be
	 * pickup up from a customer.                      
	 * 																																																
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	closeTime:string = undefined;
	/**
	 * Expanded Name : Total pieces count
	 * 
	 * Desc : Total pieces from all commodities in the shipment.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTPCS
	 * Required: false
	 * Array: false
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Exapnded Name : Motorized piece count
	 * 
	 * Desc : Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver. This count may be equal to or less than the number of pieces in the shipment.  This is used by the costing system.  
	 * 
	 * Source : MBF root -  MBMTRPCS
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * 
	 * Desc : Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * 
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * 
	 * Source : MBF: root: MBTOTWT
	 * Required: false
	 * Array: false
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded Name : Default Tariff
	 * 
	 * Desc : This identifies the default qualifying tariff for the shipment. If the rating process does not identify any other tariffs for the customer from rate aids, this is used as the rating tariff. 
	 * 	
	 * Source : MBF root - MBTARIFF
	 * Required: false
	 * Array: false
	 */
	defaultTariffsId:string = undefined;
	/**
	 * Expanded Name : Total charges
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * Required: false
	 * Array: false
	 */
	totalChargeAmount:number = undefined;
	/**
	 * Expanded Name : Total charges in US dollars
	 * 
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * 
	 * Source : MBF: root: MBTOTAL
	 * 
	 * Design Notes: This field was added as a denorm to keep the total charges in USD. Normally this field is same as the tot charges field. However this field is differnt when the rating currncy is not USD.
	 * Required: false
	 * Array: false
	 */
	totalUsdAmount:number = undefined;
	/**
	 * Expanded Name: Split Indicator
	 * 
	 * Desc: Indicates whether the shipment has been split or not. 
	 * 
	 * Valid values: Y/N
	 * 
	 * Source: MBF MBCLAS2 -  This actually replaces the BILL CLASS 2 field in the MBF.
	 * Required: false
	 * Array: false
	 */
	splitInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous Materials indicator
	 * 	
	 * Desc : Indicates whether or not a shipment includes  any commodities with hazardous materials. It is a denormalized attribute for performance puposes. 
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * 
	 * Ideally, it should be specified at the commodity level, but has been de-normalized for Shipment, since that is level at which it is currently captured
	 * 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * 
	 * Y - Can be damaged by freezing
	 * Required: false
	 * Array: false
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Signature service indicator
	 * 
	 * Desc : Indicates if anyone handling the freight is required to sign on a signature sheet. 
	 * 
	 * Source : MBF: root: MBSGSRVC (x 04  of MBFLAG9)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	signatureServiceInd:boolean = undefined;
	/**
	 * Expanded Name : Revenue Bill Indicator
	 * 
	 * Desc : Indicates whether the shipment will generate revenue for Con-way or not.
	 * 
	 * Valid Values : 
	 * 
	 * Y - generates revenue
	 * N - does not generate revenue
	 * Required: false
	 * Array: false
	 */
	revenueBillInd:boolean = undefined;
	/**
	 * Expanded Name : Manual rating indicator 
	 * 
	 * Desc : Specifies whether the shipment  in question needs to be manually rated or not. If required, it may be changed to a code indicating the reason as well for manually rating it. 
	 * 
	 * It is same as special instructions field on the FBES screen.
	 * 
	 * ==NOTE==
	 * When this indicator is set, it means the rates and charges must be manully reviewed before the bill is marked as RATED. Howerver the auto rating system is allowed to put rates and charges on the bill prior to putting it in a manual entry or manual audit queue.
	 * 
	 * This is different from the indicator AUTO_RATEABLE_IND which indicates whether the auto rating system is even allowed to put rates and charges on the bill.
	 * 
	 * Source : MBF: root: MBSCHDI (x 80  of MBFLAGB)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	manualRatingRequiredInd:boolean = undefined;
	/**
	 * Expanded Name : Audit Required Indicator
	 * 																		
	 * Desc : Used to determine if the pro requires auditing after rating. 
	 * 																	
	 * Valid Values :
	 * Y - Pro does require auditing
	 * N - Pro does not require auditing
	 * 					
	 * Source : MBF:  Root: MBFBAUDT (X 01  of MBFLAGI)
	 * Required: false
	 * Array: false
	 */
	auditInd:boolean = undefined;
	/**
	 * Expanded Name : Cash indicator
	 * 
	 * Desc : This indicates if cash was collected at the tim eof pick up or should be collected at the time of delivery.
	 * 
	 * This information is also printed on the delivery set to inform the driver to collect cash.
	 * 
	 * Source : MBF: root: MBCASH (x 10  of MBFLAG2)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	cashInd:boolean = undefined;
	/**
	 * Expanded Name : Cash collected code                    
	 * 																																																							
	 * Desc : A code indicating whether any cash was          
	 * collected at the time of pick up or delivery or not. Cash may be collected  regardless of whether it was required or not and if  so, the invoice may need to be stopped.                
	 * 																																																							
	 * It has been modelled as a code instead of an indicator 
	 * since it needs to be initialized to a value other than 
	 * NO.                                                    
	 * 																																																							
	 * Source : New                                           
	 * 																																																							
	 * Valid Values :                                         
	 * blank - Initial value                                  
	 * Y - Yes, cash was collected                            
	 * N - No cash collected on delivery
	 * Required: false
	 * Array: false
	 */
	cashCollectInd:boolean = undefined;
	/**
	 * Expanded Name : Government Bill of Lading indicator
	 * 
	 * Desc : Indicates whether this is a government shipment or not.
	 * 
	 * Source : MBF: root: MBGBLT (x 01  of MBTRAFIC)
	 * 
	 * Valid Values : Y/N
	 * Required: false
	 * Array: false
	 */
	govtBolTrafficInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * 
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * 
	 * Values : Y/N
	 * 
	 * Source : MBF, E-commerce
	 * Required: false
	 * Array: false
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Coupon Indicator
	 * 
	 * Desc : Indicates that a Coupon has been used to allow the shipment to participate in the Con-Way Guaranteed Service Program for free.
	 * 
	 * Values : Y/N
	 * 
	 * Source : BASE
	 * Required: false
	 * Array: false
	 */
	guaranteedCouponInd:boolean = undefined;
	/**
	 * Expanded Name : Purge blocked indicator
	 * 
	 * Desc : Indicates that the details cannot be purged. Usually set when there are inconsistencies for Master / MSEG or Part lot/PSEG  shipments. 
	 * 
	 * Valid values : Y/N
	 * 
	 * Source : MBF: root: MBPUBLK (x 10  of MBFLAG3)
	 * Required: false
	 * Array: false
	 */
	purgeBlockInd:boolean = undefined;
	/**
	 * Expanded Name: Last MOVR PRO Number 
	 * 
	 * Desc: The Pro number for the last MOVR PRO billed for this shipment.
	 * Required: false
	 * Array: false
	 */
	lastMoverPro:string = undefined;
	/**
	 * Expanded Name: Archive Indicator
	 * 
	 * Desc: Indicates whether the record for the PRO is an active one or one retrieved from the archive.
	 * Required: false
	 * Array: false
	 */
	archiveInd:boolean = undefined;
	/**
	 * Expanded Name: COD Indicator
	 * 
	 * Desc: Indictaes whether it is a COD shipment or not. It is a denormalized attribute for performance puposes. The actual COD information is in the MISC_LINE_ITEM entity.
	 * Required: false
	 * Array: false
	 */
	codInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Code 
	 * 
	 * Desc: This is the discount code that was applied to the shipment when it was rated. 
	 * 
	 * Source: MBF - MBRCCODE
	 * Required: false
	 * Array: false
	 */
	discountCd:string = undefined;
	/**
	 * Expanded Name :Movement Reporting SIC Code
	 * 
	 * Desc : SIC where the last movement event for the shipment  happened and reported.  This is a denormalized attribute from the MOVEMENT entity for performance purposes.
	 * 
	 * Source : MBF: root MBMOVE
	 * Required: false
	 * Array: false
	 */
	lastMoveRptgSicCd:string = undefined;
	/**
	 * Expanded Name : Movement Timestamp
	 * 
	 * Desc : Date and time, when the last movement for this shipment  occurred. This is a denormalized attribute from the MOVEMENT entity. 
	 * 
	 * Source: MBF - MBMVTM
	 * Required: false
	 * Array: false
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Expanded Name : Movement status
	 * 
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * 
	 * Source: MBF
	 * Name: Various
	 * 
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 * Required: false
	 * Array: false
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Expanded Name : Delivery qualifier
	 * 
	 * Desc : Code representing results of a specific delivery activity. The values are the same as what used to be  Event that stopped service).
	 * 
	 * This has been denormalized from Movement for performance reasons and should always be kept in sync. 
	 * 
	 * Source : MBF: root: MBD1FLG / MBD2FLG
	 * 
	 * Valid Values :
	 * A= Over Shipment
	 * B=Trapped shipment/MBD1TRAP
	 * C=Hold for customs/MBD1CSTM
	 * D=Undelivered/MBD1UNDL
	 * E=Hold for appointment/MBD1APPT
	 * F=On spotted trailer/MBD1SPOT
	 * G=Cartage
	 * H=Attempted delivery/MBD1ATMP
	 * J=All short
	 * K=Partial short/MBD1PSRT
	 * L=Refused delivery/MBD1RFSD
	 * M=Damaged shipment/MBD1RDMG
	 * N=Suspense/MBD1SUSP     - Not Used
	 * O=Wait/MBD1WAIT              - Not Used
	 * P=Purge Blocked/MBD2PBLK
	 * R=Return Status/MBD2RTRN
	 * T=Transfer/MBD2TSFR
	 * Z=Final delivery/MBD1FINL
	 * Required: false
	 * Array: false
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Expanded Name : Rating Currency Code
	 * 
	 * Desc: The rates and charges for the shipment were calculated using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	ratingCurrencyCd:RatingCurrencyCd = undefined;
	/**
	 * Expanded Name : Invoicing Currency Code
	 * 
	 * Desc:  PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * The total charges for the shipment should be displayed using this currency
	 * 
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 * Required: false
	 * Array: false
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Expanded Name: Currency Converstion Factor
	 * 
	 * Desc: PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * 
	 * Provides the currency conversion factor on the day of shipment pick up between the rating currency and invoicing currency, if they are different.
	 * Required: false
	 * Array: false
	 */
	currencyConversionFctr:number = undefined;
	/**
	 * Expanded Name : Required Delivery Date
	 * 
	 * Desc : Date the shipment is required to be delivered
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	requiredDeliveryDate:string = undefined;
	/**
	 * Expanded Name : Delivery Time Start (if any)
	 * 
	 * Desc : The start time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	startDeliveryTime:string = undefined;
	/**
	 * Expanded Name : Delivery Time End (if any)
	 * 
	 * Desc : The end time of the window specified by the customer for delivering the shipment.
	 * 
	 * Source : E-commerce
	 * Required: false
	 * Array: false
	 */
	endDeliveryTime:string = undefined;
	/**
	 * Expanded Name : International manifest from (port) code
	 * 
	 * Desc : Generated by rating. Identifies port of entry code through which freight will enter the US from another country. This may be associated with the destination city or the customer s broker.
	 * 
	 * Source : MBF: root: MBFROMPT
	 * Required: false
	 * Array: false
	 */
	fromPortCd:string = undefined;
	/**
	 * Expanded Name : International  manifest to (port) code
	 * 
	 * Desc : Generated by rating: Identifies the port of entry through which freight will enter another country.  This is either associated with the destination or with the customer s broker.
	 * 
	 * Source : MBF: root: MBTOPORT
	 * Required: false
	 * Array: false
	 */
	toPortCd:string = undefined;
	/**
	 * Expanded Name : OBC pickup delivery route
	 * 
	 * Desc : Pickup delivery route code from CAD used for sorting to print delivery sets in this order.
	 * 
	 * Source : MBF: root: MBPDRTE
	 * Required: false
	 * Array: false
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * Expanded Name : Customer Profile Instance Id
	 * 
	 * Desc : A unique, randomly-generated number that identifies a registered customer in the Con-Way Customer Profile Component.
	 * 
	 * Source : Customer Profile Component
	 * Required: false
	 * Array: false
	 */
	custProfitabilityInstId:number = undefined;
	/**
	 * Expanded Name :  Rating tariff
	 * 
	 * Desc : This identifies the actual tariff used to rate the shipment. 
	 * 
	 * Source : MBF root - MBRATTAR
	 * Required: false
	 * Array: false
	 */
	ratingTariffsId:string = undefined;
	/**
	 * Expanded Name : Rate auditor initials
	 * 
	 * Desc : Initials of the rating auditor who last audited the shipment for rates. 
	 * 
	 * Source : MBF: root: MBRTADID
	 * Required: false
	 * Array: false
	 */
	rateAuditorInitials:string = undefined;
	/**
	 * Expanded Name : Rate or rate audit queue name
	 * 
	 * Desc : The name of the rating (or the rate audit) queue used when the shipment was last rated / audited. 
	 * 
	 * Source : MBF: root: MBRATEQ
	 * Required: false
	 * Array: false
	 */
	rateOrRateAudtqNm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	heaviestCommoditySequenceNo:string = undefined;
	/**
	 * Expanded name:  Service type code.
	 * 
	 * Description:  Defines the specific type of service provided for a shipment.
	 * 
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 * Required: false
	 * Array: false
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Expanded name: Estimated Delivery Date
	 * 
	 * Description: Date that the shipment should be delivered based on standard lane days.
	 * Required: false
	 * Array: false
	 */
	estimatedDeliveryDate:string = undefined;
	/**
	 * Expanded name: Freight Bill Delivery Set Print Count
	 * 
	 * Description:
	 * The number of times that a FBDS has been printed for this shipment.
	 * Required: false
	 * Array: false
	 */
	fbdsPrintCount:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * 
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * 
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * 
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded name: Delivery Information Required Code
	 * 
	 * Desc: This code indicates that there is a requirement to capture delivery information for this shipment because of customer special requirements.  This is usually an EDI customer requirement that must be met.  The name of the person that signed for delivery and the timestamp are also captured in this record.
	 * 
	 * Values: 
	 * R - delivery info is required 
	 * C - delivery informaiton has been captured  and exists with this record
	 * blank - No delivery information required for this shipment
	 * Required: false
	 * Array: false
	 */
	deliveryInformationRqdCd:DeliveryInfoRequiredCd = undefined;
	/**
	 * Expanded name: Delivery Signature Name
	 * 
	 * Desc: This is the name of the person that signed for the delivery of the freight.  This name is required by some customers and is passed along to them via EDI.  This name is captured if the DLVR_UNFO_RQD_CD is set.
	 * 
	 * Since a shipment can be delivered in parts, (part short, etc) there can be more than one signature.  This field will hold the name of the last delivery movement.  The previous signatures can be retrieved from shipment history records.
	 * Required: false
	 * Array: false
	 */
	deliverySignatureNm:string = undefined;
	/**
	 * Expanded name: Delivery Signature TimeStamp
	 * 
	 * Desc: This is the date and time recorded when the person named in DLVR_SIG_NM_TXT signed for the shipment.
	 * Required: false
	 * Array: false
	 */
	deliverySignatureDateTime:Date = undefined;
	/**
	 * Expanded Name : Standard Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables. 
	 * 
	 * Source : OSS_SVC_STDS_A/B - TRNST_DAYS
	 * Required: false
	 * Array: false
	 */
	standardTransitDays:string = undefined;
	/**
	 * Expanded Name : Actual Transit Days (in number of days)
	 * 
	 * Desc : The number of days it actually takes to move a shipment from shipper to consignee.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the qualifying delivery event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation  (SPOT, PSRT?, RDMG?, RFSD?, FINL).
	 * Required: false
	 * Array: false
	 */
	actualTransitDays:string = undefined;
	/**
	 * Expanded Name : Transit Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (qualifying delivery event) for the calculation of ACTL_TRNST_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 * Required: false
	 * Array: false
	 */
	transitMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Warranty Indicator
	 * 
	 * Desc : Indicates whether the shipment is eligible for the money-back guarantee if moving between direct points under Tariff CNWY-599.
	 * 
	 * Valid Values : 
	 * 
	 * Y - eligible for warranty
	 * N - not eligible for warranty - default value
	 * Required: false
	 * Array: false
	 */
	warrantyInd:boolean = undefined;
	/**
	 * Expanded Name : Warranty Status Code
	 * 
	 * Desc : Identifies the warranty status of a shipment
	 * 
	 * For example, one value of this code would indicate that a warranty  claim has been filed  by the debtor if a shipment has failed service.
	 * 
	 * Valid Values:
	 * 0 - default value; This means either the shipment is not eligible (Warranty flag =  N ) or it is eligible and no one has filed a claim.
	 * 1 - The customer has filed a claim and it has not been audited.
	 * 2 - The customer has filed a claim and it was approved.
	 * 3 - The customer has filed a claim and it was denied.
	 * Required: false
	 * Array: false
	 */
	warrantyStatusCd:WarrantyStatusCd = undefined;
	/**
	 * Expanded name: Notification Code
	 * 
	 * Desc: Indicates whether or not an notification exists for this shipment.  If it exists, the details will exist in the SHM NOTFICATION table.  The notification is created in the SCO (Smart4) system.
	 * 
	 * Values:
	 * A - Appointment exists
	 * N - Notification exists
	 * O -Appointment Not Required  
	 * R- Reappointment candidate.  PSRT/RDMG Pro  could be reappointment for delivery
	 * 
	 * blank - Neither exist
	 * Required: false
	 * Array: false
	 */
	notificationCd:NotificationCd = undefined;
	/**
	 * Expanded Name: Absolute minimum charge indicator
	 * 
	 * Desc: This inidcates if the charge for this shipment is the absolute minimum charge.
	 * Required: false
	 * Array: false
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Percentage
	 * 
	 * Desc: This is the percentage discount applied to the freigt chrages and discountable accessorial charges of the shipment.
	 * Required: false
	 * Array: false
	 */
	discountPercentage:number = undefined;
	/**
	 * Expanded Name: Pricing Agreement Instance Id
	 * 
	 * Desc: This is the instance id of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== Link to BASE 5 PRC component
	 * Required: false
	 * Array: false
	 */
	priceAgreementId:number = undefined;
	/**
	 * Expanded Name: Rule set Number
	 * 
	 * Desc: This identifies the rule set of the pricing agreement that was used to rate the shipment.
	 * 
	 * ==NOTE== link to BASE 5 PRC component
	 * Required: false
	 * Array: false
	 */
	priceRulesetNbr:string = undefined;
	/**
	 * Expanded Name: Route prefix 
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 * Required: false
	 * Array: false
	 */
	routePrefix:string = undefined;
	/**
	 * Expanded Name: Route Suffix
	 * 
	 * Desc: Identifies the last Route the shipment was put on.
	 * 
	 * ==NOTE== Link to SMART 4 SCO component
	 * Required: false
	 * Array: false
	 */
	routeSuffix:string = undefined;
	/**
	 * Expanded Name: Route Type Code
	 * 
	 * Desc: Identifies whether the shipment is currently on a  Route (either planning route or dispatched route) Staged (available) for Routing, or is Not on a route.
	 * 
	 * Valid Values
	 * 				- Not Routed
	 * R - Routed
	 * S - Staged
	 * Required: false
	 * Array: false
	 */
	routeTypeCd:RouteTypeCd = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * 
	 * Desc : The volume of the freight; derived from the dimensions captured by driver, or entered by a CSR or dock worker for an SL  (Shipper Load and Count), or from one of a variety of profiles. For details, see the description of CFT_PRFL_MTHD_CD.
	 * 
	 * 06/21/2016 Cathy Doucet modified description as per Ariel/Sunya.
	 * Required: false
	 * Array: false
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total Pallet Count
	 * 
	 * Desc: Total Number of Pallets in the Shipment
	 * Required: false
	 * Array: false
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Expanded Name: Area instance Id
	 * 
	 * Desc: Each service center is divided into multiple geographic areas for delivery. This identifies the delivery area of destination service center in which this shipment s consignee is located.
	 * 
	 * Source: SMART 4 Requirement
	 * 
	 * == NOTE ==  Ties back to the geo-area stored in Oracle spatial database for each service center.
	 * Required: false
	 * Array: false
	 */
	areaInstId:number = undefined;
	/**
	 * Expanded Name: Auto Rateable Indicator
	 * 
	 * Desc: This indicates whether the auto rating system is allowed to put rates and charges on the bill. This is irrespective of whether the bill needs to be manually reviewed or not before being marked RATED. 
	 * 
	 * Valid Values: 
	 * Y - Auto rating system may put rates and charges
	 * N - Auto Rating system should not out or change any rates and charges on the bill
	 * Required: false
	 * Array: false
	 */
	autoRateableInd:boolean = undefined;
	/**
	 * Expanded Name: Motorized-Move Pieces Known Indicator
	 * 
	 * Desc: This indicates whether MTRZD_PCS_CNT is known or applicable.. 
	 * 
	 * Valid Values: 
	 * Y - Yes, MTRZD_PCS_CNT is known/applicable
	 * N - No, MTRZD_PCS_CNT is NOT known/applicable
	 * 
	 * Source:  FBES
	 * Required: false
	 * Array: false
	 */
	motorizedPiecesKnownInd:boolean = undefined;
	/**
	 * Expanded Name : Estimated Transit Days (in number of days)
	 * 
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables, adjusted by +1 if LATE_TENDER_IND =  Y 
	 * 
	 * Source : STD_TRNST_DAYS +1, if LATE_TENDER_IND =  Y
	 * Required: false
	 * Array: false
	 */
	estimatedTransitDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Days (in number of days)
	 * 
	 * Desc : The number of days it takes to move a shipment from shipper until it reaches a service stopping event.
	 * 
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the first service-stopping event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 * Required: false
	 * Array: false
	 */
	calculatedServiceDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Timestamp
	 * 
	 * Desc : The timestamp of a service stopping event.
	 * 
	 * Source : (LST_)MVMT_TMST of the first service-stopping event / movement.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 * Required: false
	 * Array: false
	 */
	calculatedServiceDateTime:Date = undefined;
	/**
	 * Expanded Name : Calculated Movement Sequence Number
	 * 
	 * Desc : The sequence number identifying the movement (service-stopping event) for the calculation of CALC_SVC_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * 
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 * Required: false
	 * Array: false
	 */
	calculatedMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Difference of Calculated Days
	 * 
	 * Desc : Difference between CALC_SVC_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : CALC_SVC_DAYS - EST_TRNST_DAYS.
	 * Required: false
	 * Array: false
	 */
	diffCalculatedDays:string = undefined;
	/**
	 * Expanded Name : Difference of Transit Days
	 * 
	 * Desc : Difference between ACTL_TRNST_DAYS and EST_TRNST_DAYS.
	 * 
	 * Source : ACTL_TRNST_DAYS - EST_TRNST_DAYS.
	 * Required: false
	 * Array: false
	 */
	diffTransitDays:string = undefined;
	/**
	 * Expanded Name : Late Tender Code
	 * 
	 * Desc : Indicates whether shipment could not be picked up in time (due to late tender by shipper) to make service.
	 * 
	 * Values: Y - Late Tender; blank or N - Not Late Tender
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up Request create time if a skeleton PRO is available; may be changed in Corrections
	 * Required: false
	 * Array: false
	 */
	lateTenderCd:LateTenderCd = undefined;
	/**
	 * Expanded Name : Shipment Service Status Code
	 * 
	 * Desc : Indicates whether shipment met service standards.
	 * 
	 * Values: Fail, Success, Exempt, Unknown
	 * 
	 * Source : Calculated
	 * Required: false
	 * Array: false
	 */
	shipmentServiceStatusCd:ServiceStatusCd = undefined;
	/**
	 * Expanded Name : Service Calculation Status Code
	 * 
	 * Desc : Indicates whether shipment service calculations have been performed.
	 * 
	 * Values:
	 * 			0 - Not Calculated Yet
	 * 			1 - Cannot Calculate (all variables not defined)
	 * 			? - Calculated from (service stopping event or appointment - replace '?' with DLVRY_QALFR_CD values)
	 * 
	 * Source : Calculated
	 * Required: false
	 * Array: false
	 */
	serviceCalculatedStatusCd:ServiceCalculationStatusCd = undefined;
	/**
	 * Expanded Name : Exemption Reason Code
	 * 
	 * Desc : Indicates why a shipment may be exempt from meeting service standards.
	 * 
	 * Values:
	 * 			blank - Not Exempt;
	 * 			any valid exemption reason code
	 * 
	 * Source : OPS_EXEMPT_SHPMT; shipment exemptions are created immediately upon full trailer and service center exemption events.
	 * Required: false
	 * Array: false
	 */
	exemptReasonCd:ExemptReasonCd = undefined;
	/**
	 * Expanded Name : Pickup Date Back-Date Indicator
	 * 
	 * Desc : Indicates whether a shipment pickup date was backdated during bill entry.
	 * 
	 * Values:
	 * 			Y - Shipment pickup date was backdated at bill entry
	 * 			N - Shipment pickup date = bill entry date
	 * 
	 * Source : Bill Entry/FBES.
	 * Required: false
	 * Array: false
	 */
	pickupBackdateInd:boolean = undefined;
	/**
	 * Expanded Name: FOOD OR POISON CODE
	 * 
	 * Desc; Indicates whether the shipment contains food or posion. Assumption: A shipment will never contain both.
	 * 
	 * Valid Values:
	 * 
	 * 1 - Neither
	 * 2 - Food
	 * 3 -  Poison
	 * Required: false
	 * Array: false
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * Expanded Name: Rating Override Code
	 * 
	 * Desc: Indicates if a rating override has been indicated during billing. For example to use a spot quote or lineal feet rating.
	 * 
	 * Valid Values:
	 * 1: Spot Quote Number Specified for rating
	 * 2: Lineal Feet Rating should be used
	 * Required: false
	 * Array: false
	 */
	ratingOverrideCd:string = undefined;
	/**
	 * Expanded Name : Pick-Up Time
	 * 
	 * Desc : Time when the shipment was picked up. This will be required for Late Tender shipments and optional for other shipments.
	 * 
	 * Source : Bill Entry/FBES; can be populated at Pick-up
	 * Request create time if a skeleton PRO is available;
	 * may be changed in Corrections
	 * Required: false
	 * Array: false
	 */
	pickupTime:string = undefined;
	/**
	 * Expanded Name : Service Start Date
	 * 
	 * Desc : Business date on which the service clock starts.  This value will be calculated automatically for all shipments, based on the values of PKUP_DT and LATE_TENDER_CD.  If a shipment is tendered late, the service start date will be set to the next business date after the pick-up date.
	 * 
	 * Source : Will be populated at pkup request create time if a skeleton PRO is available, during bill entry, through the late tender correction screen.
	 * Required: false
	 * Array: false
	 */
	serviceStartDate:string = undefined;
	/**
	 * Expanded Name: Inspected Indicator
	 * 
	 * Desc: Indicates whether the shipment has been inspected or not.
	 * Required: false
	 * Array: false
	 */
	inspectedInd:boolean = undefined;
	/**
	 * Expanded Name: Spot Quote ID
	 * 
	 * Desc: The Spot Quote ID that was entered during Billing. This may be different from the one that was used for rating.  For the latest Spot Quote ID that was used for raing, please look at the rating info record.
	 * Required: false
	 * Array: false
	 */
	spotQuoteId:number = undefined;
	/**
	 * Expanded Name: Shipper to Consignee Miles
	 * 
	 * Desc: This captures the mileage from shipper to consignee as calculated by the Milemaker package (Rand McNally)
	 * 
	 * Added by C.Doucet 05/06/09 as per Shibashis
	 * Required: false
	 * Array: false
	 */
	shipperToConsigneeMiles:number = undefined;
	/**
	 * Expanded name: Shipper loaded trailer code
	 * 
	 * Desc: Identifies whether a shipper loaded a trailer, and if so, where Con-way took possession of trailer. 
	 * 
	 * Values: 
	 * N Â Shipper did not load trailer (default)
	 * H Â Hook loaded; a trailer is loaded by shipper and picked up by Con-way at shipper s site 
	 * D Â Dock Drop; a trailer is loaded by shipper and dropped at Con-way dock by shipper
	 * Required: false
	 * Array: false
	 */
	shipperLoadedTrlrCd:ShipperLoadedTrailerCd = undefined;
	/**
	 * Expanded Name: Consignee unloaded trailer code
	 * 
	 * Desc: Identifies whether a consignee unloaded a trailer, and if so, where consignee took possession of trailer. 
	 * 
	 * Values:
	 * N Â Consignee did not unload trailer (default)
	 * D Â Dock Pickup; a loaded trailer is picked up at Con-way dock by consignee to be unloaded by consignee 
	 * S Â Spot loaded; a loaded trailer is left at the consignee site for consignee to unload
	 * Required: false
	 * Array: false
	 */
	consigneeUnloadTrlrCd:ConsigneeUnloadedTrailerCd = undefined;
	/**
	 * Expanded Name: Lineal Foot Total Number
	 * 
	 * Desc: This is the length in feet that a shipment will take up in a trailer.  This dimension is typically used when the freight is long and narrow and cannot have anything stacked on top of it (e.g. carpet rolls).  For this reason it takes a larger portion of the trailer volume than a shipment of the same weight that is packaged on pallets.  This value can then be used in the planning of trailer loads and to rate shipments that have these characteristics.
	 * Required: false
	 * Array: false
	 */
	linealFootTotalNbr:string = undefined;
	/**
	 * Expanded Name: Single Shipment Acquired Indicator                                                                                                                                                Desc:                                                                                                      This indicates whether this was the only Shipment that we acquired from the Customer at the time that we received this one.
	 * Values:
	 * Y Â Yes:  This was the only Shipment Acquired at the time that we received this one.
	 * N Â No: There were other Shipments Acquired at the time that we received this one.
	 * Required: false
	 * Array: false
	 */
	singleShipmentAcquiredInd:boolean = undefined;
	/**
	 * Desc: This code identifies the method in which Con-way received the freight from the customer.
	 * Current values are: 
	 * PU Â Regular Pickup:  This is when the driver goes to the customer dock and loads the Shipment on the trailer to return to the service center.
	 * HL Â Hook Loaded: This is when the driver goes to the customer site and pickup a loaded trailer that this Shipment was on.
	 * DD Â Dock Drop:  This is when the customer delivers the Shipment to a Con-way Service Center, so no driver went to the Customer site
	 * Required: false
	 * Array: false
	 */
	shipmentAcquiredTypeCd:ShipmentAcquiredTypeCd = undefined;
	/**
	 * Expanded Name : Loose pieces count
	 * 
	 * Desc : Total loose pieces from all commodities in the shipment.
	 * 
	 * Count of non Motor Move pieces associated with a shipment. This count plus Motor Moves defines the total number of pieces moving within the Con-way system for Operations productivity.  This does not necessarily balance with the total pieces or commodity pieces entered during bill entry.
	 * Required: false
	 * Array: false
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Expanded Name: PUP_VOLUME_PERCENTAGE
	 * 
	 * Desc: This is the volume of the shipment expressed as the percentage of a full pup (28 feet long trailer).
	 * Required: false
	 * Array: false
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * Desc: Instance id of the Pickup Request that is associated with this Shipment during the Confirm On-Board process.
	 * Required: false
	 * Array: false
	 */
	purInstId:number = undefined;
	/**
	 * Expanded Name: Bulk Liquid Indicator
	 * 
	 * Desc: Identifies the shipment as containing bulk liquid.  If volume is known, this applies to shipments >119 gallons.
	 * 
	 * Added 8/5/14 by Paul Bramhall for Tanker Endorsement Project.
	 * -------------------------------------------------------------
	 * Required: false
	 * Array: false
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cubicFeetProfileMthdCd:ShipmentVolumeMethodCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cubicFeetProfileTypeCd:ShipmentVolumeTypeCd = undefined;
	/**
	 * In the event a customer requires a shipment to travel from origin to destination 
	 * without the loading of any additional shipments on the trailer(s), the shipment is considered Exclusive Use.
	 * Required: false
	 * Array: false
	 */
	exclusiveUseInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates that this shipment is marked as a Mexico Door to Door shipment.
	 * Required: false
	 * Array: false
	 */
	mexicoDoorToDoorInd:boolean = undefined;
	/**
	 * Indicates that this shipment is time date critical and operations needs to call for a delivery appointment.
	 * Required: false
	 * Array: false
	 */
	callForAppointmentInd:boolean = undefined;
	/**
	 * The value of the freight as declared by the customer.
	 * Required: false
	 * Array: false
	 */
	declaredValueAmount:number = undefined;
	/**
	 * Indicates that the customer would like to insure the shipment due to excessive value of the freight.
	 * Required: false
	 * Array: false
	 */
	excessiveValueInd:boolean = undefined;
	/**
	 * Indicates whether the payment terms were flipped on a bill after biller submit, based on input shipment party data.
	 * Required: false
	 * Array: false
	 */
	debtorTermFlipInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	handlingUnit:HandlingUnit[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	movement:Movement[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	managementRemark:ManagementRemark[] = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	operationsShipment:OperationsShipment = undefined;
}
/**
 * Holds a snapshot of Shipment. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class ShipmentSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:Shipment = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:Shipment = undefined;
}
/**
 * 
 */
export class SuppRefNbr {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code:
	 * 
	 * Desc : A code describing what the Supplemental Reference is (for eg. PO# for Purchase Order number and GBL for Government Bill of Lading). These are entered by and have a meaning for the customer only. There is no list of valid values. 
	 * 
	 * Examples :
	 * 
	 * Purchase orders
	 * Shipper numbers
	 * GBL numbers
	 * SLI numbers 
	 * Bookclosing numbers
	 * 
	 * Source: E-commerce
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * Expanded Name : Sequence number
	 * 
	 * Desc : Sequentially assigned number to preserve the order in which various supplemental reference numbers are to be displayed / printed for a commodity / shipment. 
	 * 
	 * Source : Generated
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Number text
	 * 
	 * Desc : This is the actual number provided by the customer (sometimes also known as COSTAR - (C)ustomer (O)wned (S)hipment and (T)racking numbers). This valid only when recording supplemental references for a shipment.
	 * Required: false
	 * Array: false
	 */
	refNbr:string = undefined;
	/**
	 * Expanded Name : Description
	 * 
	 * Desc : A textual description of what the Supplemental Reference number is (Purchase order number, shipper number, etc.)
	 * Required: false
	 * Array: false
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Commodity Sequence Number
	 * 
	 * Desc : Sequence number of the specific Commodity (if any) to which the supplemental reference number applies. Should be 0 if the number applies to the Shipment in general.
	 * Required: false
	 * Array: false
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	archiveControlCd:string = undefined;
	/**
	 * Desc: Source of the Supplemental Reference Number.
	 * 
	 * Values:
	 * EDI
	 * WEB
	 * XML
	 * BIL - Billing
	 * COR - currently BE36 transaction (Aug.2011)
	 * Required: false
	 * Array: false
	 */
	sourceCd:string = undefined;
	/**
	 * Desc: Sender-ID of the EDI Customer.
	 * Required: false
	 * Array: false
	 */
	ediSenderId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	suppRefExtendedDetail:SuppRefExtendedDetail[] = undefined;
}
/**
 * Holds a snapshot of SuppRefNbr. Contains the details at the time on an event. It also provides the previous values if an update occured
 * null
 */
export class SuppRefNbrSnapshot {
	/**
	 * The action that created this snapshot. Add, Update or Delete
	 * Required: false
	 * Array: false
	 */
	eventActionCd:ActionCd = undefined;
	/**
	 * The previous snapshot, before this record was updated.
	 * Required: false
	 * Array: false
	 */
	before:SuppRefNbr = undefined;
	/**
	 * The current snapshot.
	 * Required: false
	 * Array: false
	 */
	after:SuppRefNbr = undefined;
}
/**
 * 
 */
export class ServiceOverride {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remarks:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overrideSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxuser:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxtimestamp:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	detailCapxaction:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicationLastUpdateDateTime:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class XdockException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerIdSuffixNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	loadedReleaseNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportingSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dockName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shortPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	overPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dmgdPiecesCount:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	allShrtInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventShiftCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDoor:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Request message to retrieve Shipment details from ODS.
 */
export class GetOdsShipmentQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Linehaul dimensions will be returned if shipmentDetailCd is set to LNHDIM (LinehaulDimensions).
	 * Required: false
	 * Array: true
	 */
	shipmentDetailCd:ShipmentDetailCd[] = undefined;
}
/**
 * Response to request message to retrieve Shipment details from ODS.
 */
export class GetOdsShipmentResp {
	/**
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	asMatchedParty:AsMatchedParty[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:Commodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	accessorialService:AccessorialService[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbr:SuppRefNbr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	advanceBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsBond:CustomsBond[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	miscLineItem:MiscLineItem[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timeDateCritical:TimeDateCritical = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	parentShipmentId:OdsShipmentId = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	childShipmentIds:OdsShipmentId[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	siblingShipmentIds:OdsShipmentId[] = undefined;
	/**
	 * Status of the Pro from the Pro Frt Bill Index
	 * Required: false
	 * Array: false
	 */
	proFrtBillIndexStatCd:ProStatusCd = undefined;
	/**
	 * Indicates the pickup for this shipment was a Shipper Loadd and count.
	 * Required: false
	 * Array: false
	 */
	shipperLoadAndCountInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	xpoRevenueAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custPricingAgreementId:string = undefined;
	/**
	 * Indicates the presence of ops exceptions on the shipment. True=no exceptions; False=Exceptions present.
	 * Required: false
	 * Array: false
	 */
	noOpsExceptionInd:boolean = undefined;
	/**
	 * Indicates if a shipment has two or more handling exceptions.
	 * Required: false
	 * Array: false
	 */
	shipmentHandlingExceptionInd:boolean = undefined;
	/**
	 * Indicates if a shipment has two or more handling exceptions.
	 * Required: false
	 * Array: true
	 */
	linehaulDimensions:LnhDimension[] = undefined;
}
/**
 * Request message to retrieve shipments based on multiple PRO numbers that are provided.
 */
export class ListOdsShipmentsRqst {
	/**
	 * Business identifier for the shipment.
	 * Required: true
	 * Array: true
	 */
	proNbrs:string[] = undefined;
	/**
	 * Specifies the number of months to use in retrieving shipments by age, based on Pickup Date. If a value is supplied, only shipments will be returned that have a Pickup date prior to current date - the number of months value in filterByPickupDateAgeInMonths.  For Example, if input filterByPickupDateAgeInDays is 9, only shipments that have a Pickup Date within 9months ago from current date are returned.
	 * Required: false
	 * Array: false
	 */
	filterByPickupDateAgeInMonths:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListOdsShipmentsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	odsShipments:OdsShipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve shipments based on multiple input identifiers that are provided.
 */
export class ListOdsShipmentsByIdentifiersRqst {
	/**
	 * Business identifier for the shipment.
	 * Required: false
	 * Array: true
	 */
	proNbrIdentifiers:ProNbrIdentifier[] = undefined;
	/**
	 * Business identifier for the shipment.
	 * Required: false
	 * Array: true
	 */
	shipmentInstIds:number[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListOdsShipmentsByIdentifiersResp {
	/**
	 * Required: false
	 * Array: true
	 */
	odsShipments:OdsShipmentDetail[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve Shipment details from ODS.
 */
export class ListOdsShipmentHistTraceEventsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	odsShipmentId:OdsShipmentId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to request message to retrieve Shipment details from ODS.
 */
export class ListOdsShipmentHistTraceEventsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	shipmentTraceHistoryEvents:ShipmentHistTraceEvent[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve Shipment details from ODS.
 */
export class ListShipmentHistTraceEventsRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	odsShipmentId:OdsShipmentId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to request message to retrieve Shipment details from ODS.
 */
export class ListShipmentHistTraceEventsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	shipmentTraceHistoryEvents:ShipmentHistTraceEvent[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve shipments based on multiple input identifiers that are provided.
 */
export class ListShipmentsByIdentifiersRqst {
	/**
	 * Business identifier for the shipment.
	 * Required: false
	 * Array: true
	 */
	proNbrIdentifiers:ProNbrIdentifier[] = undefined;
	/**
	 * Business identifier for the shipment.
	 * Required: false
	 * Array: true
	 */
	shipmentInstIds:number[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListShipmentsByIdentifiersResp {
	/**
	 * Required: false
	 * Array: true
	 */
	odsShipments:OdsShipmentDetail[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve shipments based on multiple PRO numbers that are provided.
 */
export class ListShipmentsRqst {
	/**
	 * Business identifier for the shipment.
	 * Required: true
	 * Array: true
	 */
	proNbrs:string[] = undefined;
	/**
	 * Specifies the number of months to use in retrieving shipments by age, based on Pickup Date. If a value is supplied, only shipments will be returned that have a Pickup date prior to current date - the number of months value in filterByPickupDateAgeInMonths.  For Example, if input filterByPickupDateAgeInDays is 9, only shipments that have a Pickup Date within 9months ago from current date are returned.
	 * Required: false
	 * Array: false
	 */
	filterByPickupDateAgeInMonths:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListShipmentsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	odsShipments:OdsShipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve Shipment details from ODS.
 */
export class GetShipmentQuery {
	/**
	 * Required: false
	 * Array: false
	 */
	shipmentInstId:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pickupDate:string = undefined;
	/**
	 * Linehaul dimensions will be returned if shipmentDetailCd is set to LNHDIM (LinehaulDimensions).
	 * Required: false
	 * Array: true
	 */
	shipmentDetailCd:ShipmentDetailCd[] = undefined;
}
/**
 * Response to request message to retrieve Shipment details from ODS.
 */
export class GetShipmentResp {
	/**
	 * Required: false
	 * Array: false
	 */
	shipment:Shipment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	asMatchedParty:AsMatchedParty[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	commodity:Commodity[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	accessorialService:AccessorialService[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	suppRefNbr:SuppRefNbr[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	remark:Remark[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	advanceBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	customsBond:CustomsBond[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	miscLineItem:MiscLineItem[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timeDateCritical:TimeDateCritical = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	parentShipmentId:OdsShipmentId = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	childShipmentIds:OdsShipmentId[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	siblingShipmentIds:OdsShipmentId[] = undefined;
	/**
	 * Status of the Pro from the Pro Frt Bill Index
	 * Required: false
	 * Array: false
	 */
	proFrtBillIndexStatCd:ProStatusCd = undefined;
	/**
	 * Indicates the pickup for this shipment was a Shipper Loadd and count.
	 * Required: false
	 * Array: false
	 */
	shipperLoadAndCountInd:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	xpoRevenueAmt:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	custPricingAgreementId:string = undefined;
	/**
	 * Indicates the presence of ops exceptions on the shipment. True=no exceptions; False=Exceptions present.
	 * Required: false
	 * Array: false
	 */
	noOpsExceptionInd:boolean = undefined;
	/**
	 * Indicates if a shipment has two or more handling exceptions.
	 * Required: false
	 * Array: false
	 */
	shipmentHandlingExceptionInd:boolean = undefined;
	/**
	 * Indicates if a shipment has two or more handling exceptions.
	 * Required: false
	 * Array: true
	 */
	linehaulDimensions:LnhDimension[] = undefined;
}



